#assessment-plan {
  .heading {
    width: auto;
    span {
      display: inline-block;
      position: relative;
      top: -2px;
      width: 8px;
      height: 8px;
      border-width: 0 0 1.8px 1.8px;
      border-style: solid;
      border-color: #252e4b;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .addAssessment-button {
    position: absolute;
    margin-top: -40px;
    height: 40px;
    padding: 0 16px;

    background: #ffffff;
    border: 1px solid #dadae4;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #252e4b;
    transition: border 1s;
  }

  .addAssessment-button:hover {
    border: 1px solid #4436ea;
    color: #4436ea;
  }
  .menu-options {
    border: 0;
    padding: 4px;
    z-index: 101;
    list-style-type: none;
    position: absolute;
    top: 32px;
    left: 8px;
    width: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(62, 72, 84, 0.26);
    border-radius: 8px;

    li:hover {
      background: #f3f5f9;
      border-radius: 8px;
    }

    li {
      display: flex;
      align-items: center;
      padding: 7px 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #252e4b;
      cursor: pointer;
      background: #ffffff;
      /* Add transition for smooth color change */
      transition: background 0.4s ease;
      svg {
        margin-right: 10px;
      }
    }

    .delete {
      color: #ff475d;
      border-top: 1px solid #eff0f2;
    }
  }
  .assessments-data-section {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(62, 72, 84, 0.11);
    border-radius: 12px;
  }

  .job-position-list:first-of-type {
    border-top: none;
  }

  .job-position-list {
    border-top: 1px solid #eff0f2;

    .listdetails {
      height: 122px;
      background: #ffffff;
      padding: 8px;

      .left {
        display: flex;
        // align-items: center;
        height: 100%;

        img {
          width: 164.71px;
          height: 106px;
          border-radius: 8px;
          object-fit: cover;
        }

        .position-details {
          margin-left: 13px;
          display: flex;
          flex-direction: column;

          span {
            // font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #252e4b;
          }

          .position {
          }

          .topics {
            // font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 122.2%;
            color: #5f6989;
            margin-top: 7px;
          }

          .question {
            display: flex;
            margin-top: 17px;

            span {
              display: flex;
              align-items: center;
              margin-right: 23px;
              // font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.01em;
              color: #252e4b;

              svg {
                margin-right: 7px;
              }
            }
          }
        }
      }

      .right {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // button {
        //   display: flex;
        //   flex-direction: row;
        //   justify-content: center;
        //   align-items: center;
        //   padding: 8px 12px 8px 16px;
        //   gap: 8px;
        //   width: 188px;
        //   height: 40px;
        //   background: #ffffff;
        //   border: 1px solid #dadae4;
        //   box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        //   border-radius: 8px;
        //   margin-right: 20px;

        //   // font-family: 'Inter';
        //   font-style: normal;
        //   font-weight: 400;
        //   font-size: 15px;
        //   line-height: 24px;

        //   text-align: center;
        //   color: #252e4b;

        // }

        .qsn-info-section {
          display: flex;
          height: 60px;
          background: rgba(239, 240, 242, 0.4);
          border-radius: 12px;
          padding: 13px 0px;

          .sub-section:first-of-type {
            border-left: none;
          }

          .sub-section {
            padding-left: 22px;
            padding-right: 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #e1e1ee;

            .header {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #252e4b;
            }

            .detail {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #5f6989;
            }
          }
        }

        .menu-button {
          width: 40px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #dadae4;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 8px;
          margin-left: 24px;
          transition: border 0.7s ease;
        }

        .menu-options {
          right: 0;
          top: 80px;
          width: 180px;
          left: initial;
        }
      }
    }
  }
}


#EvaluationGraph {
  .row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;

    button {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      border: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      // transform: rotate(45deg);
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      opacity: 0.5;
    }

    button:hover {
      box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
    }

    .active {
      opacity: 2;
      // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1)
    }
  }
  .rd3t-node {
    fill: rgb(54, 116, 232);
    stroke: none;
  }
  .rd3t-label__title {
    // fill: green;
    font-size: 14px;
    font-weight: 400;
  }
  .rd3t-label__attributes {
    fill: green;
    font-size: 10px;

    tspan {
      x: 0;
    }
  }

  /* Your styles here */
  /* For example: */
  // transform: translate(0, 0) scale(0, 0);

  .node__root > circle {
    fill: red;
  }

  .node__branch > circle {
    fill: yellow;
  }

  .node__leaf > circle {
    fill: green;
    /* Let's also make the radius of leaf nodes larger */
    r: 40;
  }
}
