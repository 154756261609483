.no-jobs {
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 88px);
  .data-section {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
    border-radius: 8px;
    padding: 48px 64px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h2 {
    margin: 0 0 30px;
  }
  button {
    display: block;
    @include button(
      182px,
      44px,
      $gradient-color-left: #0b53dd,
      $text-weight: 700
    );
    &:hover{
        border-width: 3px;        
    }
    .symbol {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}
