#assessment-scores {

  .header-row {
    display: flex;
    // justify-content: space-between;
    // align-items: center;

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      margin-top: -40px;
    }
    
    .switch input[type="checkbox"] {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: #2196F3;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    

    button {
      height: 40px;
      width: auto;
      padding: 4px 12px 4px 10px;
      background: #ffffff;
      border: 1px solid #dadae4;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #252E4B;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .data-row,
  .heading-row {
    justify-content: space-between;

    .score,
    .date {
      min-width: 180px;
      display: flex;
      align-items: center;
      cursor: pointer;


    }

    .drop-down {
      .menu-options {
        position: absolute;
        // right: 0;
        top: 20px;
        left: auto;
        text-align: left;
        background: #ffffff;
        box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
        border-radius: 4px;
        border: 0;
        padding-bottom: 8px;
        z-index: 9999;
        list-style: none;
  
        li:hover {
          background-color: #e4efff
        }
  
        li {
          margin-top: 8px;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          background-color: #ffffff;
          cursor: pointer;
          transition: background-color 0.2s;
          width: 100%;
          padding-left: 16px;
          padding-right: 16px;
  
        }
      }
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-left: 1px solid #252e4b;
        border-bottom: 1px solid #252e4b;
        margin-left: 8px;
      }

      .expand{
        transform: rotate(135deg);  
        margin-top: 8px;
      }

      .notExpand{
        transform: rotate(315deg);
      }
    }



    .user,
    .action {
      max-width: calc(50% - 180px - 42px);
    }

    .action {
      min-width: 200px;
    }
  }

  .data-list {
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(62, 72, 84, 0.11);
    border-radius: 12px;

    .data-row {
      height: 72px;
      margin-top: 0;
      cursor: initial;
      border-bottom: 1px solid#EFF0F2;
      border-radius: 0;

      &:hover {
        background-color: white;
      }

      button.full-report {
        width: auto;
        padding: 4px 12px 4px 10px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #dadae4;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #252E4B;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          border-color: rgb(168, 168, 168);
          color: rgb(168, 168, 168);
          opacity: 0.6;
          cursor: auto;
          pointer-events: none;
        }

        svg {
          margin-right: 6px;
        }
      }

      button.expand-button,
      button.collapse-button {
        width: auto;
        height: auto;
        border: 0;
        background: transparent;
        padding: 0;
        margin-left: 24px;

        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-left: 1px solid #252e4b;
          border-bottom: 1px solid #252e4b;
        }
      }

      .expand-button span {
        -webkit-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
        transform: rotate(315deg);
      }

      .collapse-button span {
        -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        transform: rotate(135deg);
      }


      .personal-info {
        .name {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #252E4B;
        }

        .email {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #5F6989;
        }

      }
    }

  }



  .expand-details {
    padding: 20px 22px;
    background: rgba(242, 244, 248, 0.5);

    .left-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.04em;
        color: #16182f;
        margin-top: 0;
      }

      h2 {
        font-size: 18px;
        line-height: 22px;
        color: #252e4b;
        margin-top: 0;
      }

      .graph {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px 20px 40px;

        svg {
          position: absolute;

          .arc:first-of-type path {
            fill: #eff0f2;
          }
        }

        span {
          font-size: 25px;
          font-weight: 500;
          font-style: normal;
        }
      }
    }

    .score-table {

      .data-row,
      .heading-row {
        background-color: transparent;
        display: flex;
        color: #252e4b;

        .col-xs-2 {
          width: 90px;
        }

        .col-xs-4 {
          width: 180px;
        }

        .col-xs-6 {
          width: calc(100% - 270px);
          max-width: 360px;
        }
      }

      .heading-row {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        height: 20px;
        margin-bottom: 18px;
      }

      .data-row {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        height: 54px;

        .col-xs-6 {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
        }
      }

      .legend-color {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
  }
}