.notification-menu{
    position: absolute;
    width: 500px;
    min-height: 60px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.4);
    top: 50px;
    padding-bottom: 50px;
    .bottom-section{
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 12px 0;
    border-top: 1px solid #bfd6ff;
    }
}
#notification-page {
    .headingText{
        margin-bottom: 40px;
    }
  .ntf-post {
    width: 100%;
    min-height: 60px;
    display: flex;
    margin-bottom: 20px;
    padding: 0 16px;
    align-items: center;
    justify-content: flex-start;
    border-left: 3px solid #82abf9;
    .logo {
      min-width: 60px;
      max-width: 60px;
      height: 60px;
      word-wrap: break-word;
      border: 1px solid darkcyan;
      color: #82abf9;
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
  }
}
