.recruiter-pages#create-profile {
  display: flex;
  align-items: center;
  .main-content {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    .container {
      display: flex;
      height: 100%;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    .sub-container {
      width: 872px;
      box-shadow: 0px 4px 24px rgba(0, 27, 75, 0.06);
      border-radius: 24px;
      padding: 50px 5%;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .headingText {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      font-family: $sans-serif;
      letter-spacing: 0.01em;
      color: $color5;
      text-align: center;
    }

    input[type="text"] {
      @include input-field(654px, 64px);
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      font-family: $sans-serif;
      letter-spacing: 0.02em;
      color: $color5;
    }

    label {
      width: 654px;
      margin-top: 30px;
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: $color5;
    }

    button {
      @include button(356px, 56px);
      margin: 64px 0 0;
    }
  }
}
