
// Default scrollbar
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d0d0d0;
  }
  