#interview-report {
  background: #f3f5f9;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100vh;

  header {
    height: 72px;
    background: #16182f;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 94;
    padding: 24px 60px;
    border-radius: 0;
    img {
      height: 24px;
    }
  }
  .tag {
    display: inline-block;
    width: auto;
    height: 28px;
    border-radius: 68px;
    border: 1px solid #dadae4;
    background: #e9e9e9;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 5px 8px;
    color: #252e4b;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    line-height: 18px;
    .circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 6px;
      border-radius: 50%;
      background-color: red;
    }
  }
  main {
    align-self: center;
    padding: 80px 16% 100px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100vw;
      top: 0;
      height: 466px;
      background: #16182f;
      left: 0;
    }

    .top-section {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }
      .user {
        width: auto;
        margin: 30px 0 40px;
        display: flex;
        .left {
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
          }
          .email {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.6);
          }
        }
        .right {
          width: auto;
          text-align: right;
          margin-left: 50px;
          button {
            padding: 9px 16px 9px 12px;
            gap: 8px;
            width: auto;
            height: 40px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            background-color: transparent;
            &:hover {
              background: white;
              color: #16182f;
            }
          }
        }
      }
    }
    h2 {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #252e4b;
    }

    .main-card {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(62, 72, 84, 0.1);
      border-radius: 16px;
      margin-bottom: 32px;
      padding: 12px 32px 24px;

      .accordion {
        background: rgba(239, 240, 242, 0.4);
        border-radius: 16px;
        padding: 8px;
        margin-top: 25px;
        h4 {
          padding: 4px 16px;
          display: inline-block;
        }
        .question-sec {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          background: white;
          margin-top: 1.5px;
          .question {
            width: 90%;
            padding: 18px 0 0;

            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #252e4b;
          }
          .sample {
            width: auto;
            padding: 4px 14px;
            margin-top: 5px;
            margin-bottom: 2.5px;
            color: #252e4b;
            text-decoration: "underline";
            cursor: "pointer";
            border-radius: 8px;
            border: 1px solid #dadae4;
            background: white;
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            &:hover {
              background-color: #dadae4a0;
            }
          }
          .score {
            padding: 4px 10px;
            border-radius: 4px;
            height: 24px;
            width: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            svg {
              width: 18px;
              height: 18px;
            }
            span {
              text-align: center;
              color: #16182f;
              font-size: 16px;
              line-height: 1;
            }
          }

          .answer-section {
            p.label {
              padding: 12px 0 0;

              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              display: inline-block;
              color: #5f6989;
            }
          }
        }
      }
    }
    .overall {
      padding: 0;
      .overall-section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
      }
      h2 {
        width: 100%;
        margin-top: 0;
        margin-bottom: 30px;
      }
      h3 {
        color: #252e4b;
        font-size: 18px;

        font-weight: 500;
        line-height: 25.3px;
        margin: 0;
      }
      & > div {
        padding: 32px;
      }
      .highlights {
        border-top: 1px solid #e1e1ee;
        background: #f9f9f9;
        border-radius: 0 0 16px 16px;
        .experience {
          padding-right: 0;
          padding-left: 24px;
        }
        h3 {
          margin-bottom: 20px;
        }
        h5 {
          color: #252e4b;
          font-size: 16px;
          font-weight: 500;
        }
        p {
          color: #252e4b;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
        }
      }
      h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.04em;
        color: #16182f;
        margin-top: 0;
      }
      .scores {
        border-bottom: 1px solid #eff0f2;
        padding: 14px 0;
        display: flex;
        justify-content: space-between;
        min-width: 310px;
        h3 {
          width: 180px;
        }
        .score-bar {
          width: calc(100% - 240px);
          padding: 7px 16px;
          float: left;
          .full {
            width: 100%;
            height: 12px;
            background: #eff0f2;
            border-radius: 6px;
          }
          .score {
            max-width: 100%;
            position: relative;
            height: 12px;
            background: #1369e9;
            border-radius: 6px;
            z-index: 2;
          }
        }
        span {
          width: 50px;
          color: #16182f;
          text-align: center;
          font-size: 20px;

          font-weight: 500;
          line-height: normal;
        }
      }
    }
    #gauge-chart {
      max-width: 475px;
      text {
        fill: #16182f !important;
      }
    }
    .interview {
      .header {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        &:first-of-type:hover svg path {
          stroke: teal;
          stroke-width: 3px;
        }
      }
      .bottom-header{
        flex-wrap: wrap;
        flex-direction: column;
      }
      h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
      }
      .interviewer span {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        &.interviewer-name {
          color: #252e4b;
        }
        &.interviewer-text {
          color: #5f6989;
          margin-left: 12px;
        }
      }
      .header-right {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        svg {
          margin-left: 32px;
        }
      }
      .date {
        text-align: right;
        .label-text {
          color: #5f6989;
          font-size: 14px;
          padding: 0 0 6px;
        }
        .value {
          font-size: 16px;
          font-weight: 500;
          color: #252e4b;
        }
      }
      .overall-score {
        margin-top: 20px;
        h2 {
          color: #16182f;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.96px;
          margin: 0;
        }
        h3 {
          margin: 0;
          color: #252e4b;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

#sample-ans-modal {
  .modal-content {
    span {
      @include text16;
      font-weight: 500;
    }
  }
  .modal-content {
    border-radius: 12px;
    padding: 16px 18px 18px 18px;

    .modal-close-button {
      display: flex;
      justify-content: space-between;

      button {
        border: none;
        padding: 0;
      }
    }

    h4 {
      color: #252e4b;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    .question {
      padding: 18px 18px;
      border-radius: 12px;
      border-bottom: 1px solid var(--gray-gray-3, #eff0f2);
      background: #fff;
    }

    .question-answer-section {
      border-radius: 12px;
      background: #fff;
      // padding: 18px;
      margin-top: 22px;

      .question-number {
        color: var(--gray-gray-8, #252e4b);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        /* 137.5% */
      }

      .question-text {
        color: var(--gray-gray-8, #252e4b);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        /* 143.75% */
      }

      .sample-answer-tag {
        color: var(--gray-gray-7, #5f6989);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 18px;
      }

      .text-section {
        border-radius: 11.25px;
        margin-top: 12px;
        background: var(--gray-gray-2, #f9f9f9);
        // padding: 16px;

        span {
          color: rgba(37, 46, 75, 0.85);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }

        .answer-text {
          padding: 12px 16px 12px 16px;
        }

        .header-sample-ans {
          border-radius: 8px 8px 0px 0px;
          background: var(--moray-eel-green-1, rgba(0, 172, 133, 0.1));
          padding: 8px 18px 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .header-tag {
            display: flex;
            color: #252e4b;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            svg {
              margin-right: 6px;
            }
          }
        }
      }
    }

    .questions-tag {
      display: flex;
      flex-wrap: wrap;

      .difficulty-tag {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 5px;
        padding-left: 0;
        margin-right: 30px;

        .color {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .subject-tag {
        margin-top: 10px;
        border: 1px solid var(--gray-gray-3, #eff0f2);
        background: #fff;
        display: flex;
        padding: 5px;
        border-radius: 7px;
        margin-right: 15px;
      }
    }
  }
}
#report-invite {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    width: 50%;
    height: 40px;
    padding-left: 36px;
    background: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2714%27 viewBox=%270 0 16 14%27 fill=%27none%27%3E%3Cg clipPath=%27url%28%23clip0_1333_123326%29%27%3E%3Crect x=%270.727539%27 y=%270.831249%27 width=%2714.5455%27 height=%2712.6%27 rx=%273%27 stroke=%27%235F6989%27/%3E%3Cpath d=%27M0.727539 3.63125L5.55989 7.35216C6.9983 8.45974 9.00223 8.45974 10.4406 7.35216L15.273 3.63125%27 stroke=%27%235F6989%27 strokeLinecap=%27round%27 strokeLinejoin=%27round%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27clip0_1333_123326%27%3E%3Crect width=%2716%27 height=%2714%27 fill=%27white%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 10px center;
  }
  .send-button {
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    width: 84px;
    background: #1369e9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    margin-left: 8px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .cancel-button {
    position: absolute;
    border: none;
    background: none;
    right: -35px;
    top: -65px;
  }
}
