#editDescription {
  padding-right: 48px;
  
  .section:not(:first-of-type){
    margin-top: 32px;
  }
  .heading-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #363636;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .wrapper-class {
    margin-top: 16px;
    border: 1.6px solid #c8c8c8;
    border-radius: 4px;
  }



}
