#resume-browser {
  background-color: #f4f8ff;
  font-family: $sans-serif;
  font-style: normal;
  color: #363636;
  padding-bottom: 52px;
  margin-top: 20px;

  .main-content {

    .add-candidate {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        font-size: 16px;
        font-weight: 600;
      }

      .upload-resume-button {
        display: flex;
        justify-content: center;
        border: 1px solid #1b5fe1;
        width: 140px;
        font-size: 15px;
        font-weight: 500;
        background: #ffffff;
        color: #1b5fe1;
        border-radius: 4px;
        padding-left: 8px;
        padding-right: 8px;
      }

      .menu-options {
        border: 0;
        padding: 8px 0;
        z-index: 101;
        list-style-type: none;
        position: absolute;
        background: #ffffff;
        box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
        border-radius: 4px;
        right: 0;
        width: 140px;
        top: 32px;
        padding-left: 10px;
         label{
          font-size: 15px;
          font-weight: 500;
         }
      }
    }

    .left-section {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgb(0 27 75 / 4%);
      border-radius: 8px;
      height: calc(100vh - 68px - 74px);
      width: 300px;
      margin-right: 20px;

      .subsections {
        border-bottom: 1px solid #dce8ff;
        padding-bottom: 20px;
      }



      button {
        background: none;
        border: none;
        padding: 0;
      }

      h6 {
        font-size: 16px;
        cursor: pointer;
      }

      .list-items {
        background: #f4f8ff;
        margin-right: 10px;
        padding: 5px;

        svg {
          margin-left: 10px;
        }
      }

      .auto-complete-list {}

      ul {
        position: absolute;
        z-index: 100;
        height: 300px;
        background: #ffffff;
        box-shadow: 0 8px 8px 0 rgb(0 0 0 / 32%);
        padding: 20px;
        overflow-y: scroll;
        list-style: none;
        top: 30px;

        li {
          font-size: 14px;
          line-height: 24px;
          color: #363636;
        }

        li:hover {
          background: #f4f8ff;
        }

      }
    }


    .left-section,
    .right-section {
      height: calc(100vh - $navbar-height - 74px);
      overflow-y: auto;
      border: solid white;
      border-width: 10px 0;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }



    .right-section {
      width: calc(100% - 320px);
      padding: 10px 16px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgb(0 27 75 / 4%);
      border-radius: 8px;
    }

    .heading-section {
      display: flex;
      justify-content: space-between;
    }

    .filter-section {
      height: calc(100vh - $navbar-height - 150px);
      overflow-y: auto;
    }

    .button-section {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-around;

      button {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        border-radius: 4px;
        @include button(calc(50% - 16px), 36px);

        &.clear-btn {
          background: #c5c5c5;
          color: #363636;
          margin-left: 16px;
        }
      }
    }

    .headingText {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      padding-left: 15px;
    }

    .heading-row {
      border-bottom: 1px solid #e1edff;
      padding-left: 16px;
      display: flex;
      justify-content: space-around;

      h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #363636;

        &:nth-of-type(1) {
          width: 28%;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 16%;
        }

        // &:nth-of-type(5),
        &:nth-of-type(6) {
          width: 12%;
        }
      }
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      color: #363636;
      text-transform: lowercase;
    }

    h5::first-letter {
      text-transform: capitalize;
    }

    .status {
      display: flex;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        background: #d9d9d9;
        border-radius: 1px;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #ffffff;
        margin-right: 13px;
      }
    }

    .content-table {
      margin-top: 30px;

      .header-row,
      .data-row {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: stretch;

        .actions {
          justify-self: center;
          // width: 200px;
        }

        .highlights {
          margin-right: calc(16.666667% - 200px);
        }
      }

      .header-row {
        box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.08);
        background: #ffffff;
        border-radius: 4px;
        align-items: center;
        font-weight: 600;
        padding: 16px 0;

        h5 {
          margin: 0;
        }
      }

      .content-data {
        margin-top: 12px;

        .data-row {
          font-weight: 500;
          padding: 10px 0;
          margin-bottom: 10px;
          @include text16;

          &:hover {
            background-color: #f4f8ff;
          }
        }

        .name {
          display: flex;
          flex-direction: column;

          span {
            display: inline-block;
            width: calc(100% - 90px);
          }

          .name_txt {
            font-size: 15px;
            font-weight: 600;
          }
        }

        .actions {
          display: flex;
          // justify-content: space-between;
          flex-direction: column;

          .reject {
            @include button(auto,
              36px,
              $gradient-color-left: #b80000,
              $gradient-color-right: #b80000,
              $fill-color: transparent,
              $text-color: white);
            display: inline-flex;
            font-weight: 600;
          }

          .approve {
            @include button(auto,
              36px,
              $gradient-color-left: #0b53dd,
              $gradient-color-right: #0b53dd);
            font-weight: 600;
            display: inline-flex;
          }
        }

        .highlights {

          .skill,
          div {
            display: inline-block;
            padding: 5px 10px;
            background-color: #f4f8ff;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
            width: auto;
          }
        }

        .links {
          display: flex;
          justify-content: flex-start;

          img {
            width: 30px;
            height: 30px;
            margin-right: 16px;
          }
        }
      }
    }

    .data-row {
      display: flex;
      flex-direction: row;
      min-height: 50px;
      background: #ffffff;
      align-items: center;
      margin-top: 16px;
      padding-left: 16px;
      justify-content: space-around;
      border-bottom: 1px solid #dce8ff;

      .data-item {
        &:nth-of-type(1) {
          width: 28%;
          text-transform: capitalize;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          width: 16%;
          text-transform: capitalize;
        }

        // &:nth-of-type(5),
        &:nth-of-type(6) {
          width: 12%;
        }

        .imageLogo {
          display: flex;
          border-radius: 50%;
          width: 64px;
          height: 64px;
          margin-right: 20px;
          object-fit: cover;
          background-color: #294568;
          align-items: center;
          justify-content: center;
          color: #ffffff;

          span {
            font-weight: 600;
            font-size: 40px;
            line-height: 24px;
          }
        }

        img {
          border-radius: 50%;
          width: 64px;
          height: 64px;
          background-color: white;
          display: inline-block;
          object-fit: cover;
        }

        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 8px;
          float: left;
        }

        h6 {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin: 0;
          float: left;
        }
      }
    }

    .data-row:hover {
      background: #f4f8ff;
    }





  }
}