@import "variables";
@import "font_style";

.no-padding {
  padding: 0;
}

.main-body {
  min-height: 100vh;
   font-family: $sans-serif;
  * {
   
    // color: $color5;
    // font-weight: 500;
    // font-style: normal;
  }
  .nav-bar,
  .page {
    // padding-left: calc($sidebar-width + 20px);
    padding-right: 20px;
  }
  .page-fade {
    background: rgba(0, 27, 75, 0.32);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 202;
  }
  .page {
    // padding-top: calc($navbar-height);
    // padding-bottom: calc($navbar-height - 20px);
    min-height: 100vh;
    width: 100%;
    float: left;
  }
  .page-container {
    padding: 0;
    padding-bottom: 116px;
  }
  button {
    max-width: 100%;
  }
  label.required::after {
    content: "*";
    color: red;
    position: relative;
    left: 4px;
  }
  input,
  select,
  textarea {
    // max-width: 100%;
    // background-color: transparent;
    // border: 1.4px solid #c8c8c8;
    // box-shadow: 1px 1px 6px rgba(124, 124, 124, 0.08);
    // border-radius: 4px;
    // padding: 0 16px;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      outline: 0;
      // box-shadow: 1px 2px 6px rgba(102, 102, 102, 0.12);
    }
  }
  textarea {
    padding: 8px 16px;
  }
  select {
    appearance: none;
    background-image: url("https://sproutsweb-assets.s3.amazonaws.com/common-assets/select-down.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 35px 24px;
  }
  [data-tooltip] {
    &::after {
      content: attr(data-tooltip);
      display: none;
      position: absolute;
      max-width: 200px;
      height: auto;
      padding: 4px 10px;
      background: white;
      box-shadow: 0 0 4px 0px rgba(124, 124, 124, 0.8);
      color: black;
      z-index: 5;
    }
    &:hover::after {
      display: block;
    }
  }
}

@import "../auth/auth_pages";
@import "./header";
@import "./sidebar";
@import "./components";
@import "./notification";
@import "list_details";
@import "../candidate/candidate";
@import "../candidate/dashboard";
@import "../candidate/description";
@import "../candidate/create_profile";
@import "../candidate/profile_page";
@import "../candidate/saved_jobs";
@import "../candidate/assessment";
@import "../candidate/assessments/dashboard";
@import "../candidate/assessments/coding_section";
@import "../recruiter/recruiter";
@import "../recruiter/interview_report";
@import "../recruiter/submitted_candidates";
@import "../recruiter/add_candidate_uploader";
@import "../recruiter/resume_browser";
@import "../recruiter/jobs/job_section";
@import "../recruiter/candidate/candidate_page";
@import "../recruiter/forms/create_company";
@import "../recruiter/forms/create_job";
@import "../recruiter/forms/create_profile";
@import "../recruiter/bot";
@import "../recruiter/assessment_report";
@import "../user/_setting";
@import "../analytics/analytics";
@import "../admin/admin";
