#assessment-dashboard {
  // background-color: #f3f5f9;


  .loader{
    position: absolute;
  }
  .main-content {
    .header-dashboard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;

      label {
        height: 40px;
        width: auto;
        display: flex;
        align-items: center;
        background: #1369E9;
        justify-content: center;
        border: 2px double #0b53dd;
        border-radius: 8px;
        padding: 8px 16px;
        font-size: 14px;
        line-height: 1.4;
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
          background: #252e4b;
          color: #dadae4;
        }
      }

      h1 {
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: #16182f;
      }

      select {
        width: 180px;
        height: 40px;
        background-color: #ffffff;
        border: 1px solid #e1e1ee;
        border-radius: 8px;
        margin-left: 14px;
      }
    }

    .job-position-list {
      margin-top: 28px;

      .listdetails {
        height: 122px;
        background: #ffffff;
        box-shadow: 0px 2px 5px rgba(62, 72, 84, 0.11);
        border-radius: 12px;
        padding: 8px;
        margin-bottom: 16px;

        .left {
          display: flex;
          align-items: center;
          height: 100%;

          img {
            width: 164.71px;
            height: 106px;
            border-radius: 8px;
            object-fit: cover;
          }

          .position-details {
            margin-left: 13px;
            display: flex;
            flex-direction: column;

            span {
              // font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 22px;
              color: #252e4b;
            }

            .position {}

            .topics {
              // font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 122.2%;
              color: #5f6989;
              margin-top: 7px;
            }

            .question {
              display: flex;
              margin-top: 17px;

              span {
                display: flex;
                align-items: center;
                margin-right: 23px;
                // font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.01em;
                color: #252e4b;

                svg {
                  margin-right: 7px;
                }
              }
            }
          }
        }

        .right {
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 12px 8px 16px;
            gap: 8px;
            width: 188px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #dadae4;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
            border-radius: 8px;
            margin-right: 20px;

            // font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;

            text-align: center;
            color: #252e4b;

            
          }
          .assessment-btn{
            &:hover {
              background: #252e4b;
              color: #dadae4;
              svg{
                path{
                  stroke: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }

  .empty-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 108px);

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: #16182f;
    }
  }
}