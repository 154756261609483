#fileUpload {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1001;
  // right: 24px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
  // height: 500px;
   max-height: 300px;
  overflow-x: scroll;
  width: 500px;
  background: #ffffff;
  // background: #85adf9;

  .down-button {
    background: none;
    border: none;
    position: absolute;
    padding: 0;
    right: 35px;
    top: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .cancel-button {
    background: none;
    border: none;
    position: absolute;
    padding: 0;
    right: 5px;
    top: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .header {
    // background: #f4f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0ebff;
    height: 50px;
    border-radius: 10px 10px 0px 0px;

    .button {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 8px;
      background: #ffffff;
      border: none;
      letter-spacing: -0.005em;
      color: #0b53dd;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .bottom {
    margin-top: 20px;
    // height: 500px;
    // min-height: 100px;
     max-height: 800px;
    overflow-x: scroll;

    span {
      font-size: 14px;
      line-height: 1.4;
      max-width: 400px;
      // overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .list-resume {
      display: flex;
      justify-content: space-around;
    

      .remove-resume-button {
        background: none;
        border: none;
        padding: 0;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}