#assessment-invitees {
  margin-top: -36px;

  button.invite-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    width: 78px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DADAE4;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #252E4B;
    position: relative;
  }
  .header-row{
    margin-top: 16px;
  }
  .data-item span{
    display: inline-flex;
    align-items: center;
    &:hover{
      font-weight: 600;
      cursor: pointer;
    }
  }
  .data-list .data-row {
    height: 72px;
    margin-top: 0;
    cursor: initial;
    border-bottom: 1px solid#EFF0F2;
    border-radius: 0;

    button {
      width: auto;
      height: 30px;
      border-radius: 4px;
      border: 2px solid $color3;
      background: white;
      padding: 0 16px;
      letter-spacing: -0.005em;
      color: #0b53dd;
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;

      &:disabled {
        border-color: rgb(168, 168, 168);
        color: rgb(168, 168, 168);
        opacity: 0.6;
        cursor: auto;
        pointer-events: none;
      }
    }

    .status span {
      padding: 4px 14px;
      width: 93px;
      height: 32px;
      display: inline-block;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      &.accepted{
        background: #e8f0fd;
        color: #1369e9;
      }
      &.expired{
        background: #a1a1a1;
        color: #FFFFFF;
      }
    }

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #252E4B;
    }
  }

  .user {
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #252E4B;

    }

    .email {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #5F6989;

    }
  }

  .date {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #252E4B;
  }

  // .data-row,
  // .heading-row {
  //   justify-content: space-between;
  // }
  // .heading-row h5:nth-of-type(2) {
  //   // padding: 0;
  // }
}