#description .analytics-dashboard #edit-skills {
  margin-top: -20px;
  .section {
    margin: 0 0 20px;
    padding: 20px 10px 10px;
    background-color: transparent;
    &:hover {
      background-color: #f3f7ff;
    }
  }
  .input-section label,
  .line span {
    @include text16;
  }
  input {
    height: 36px;
    &[type="number"] {
      padding-right: 0;
    }
  }
  .section-header {
    border: 0;
    align-items: center;
    input,
    .value {
      font-size: 18px;
      line-height: 36px;
      &.col-xs-10 {
        width: calc(83% - 70px);
      }
    }
    .icon,
    .icon svg {
      width: 16px;
      height: 16px;
    }
    .icon.drag,
    .icon.drag img {
      width: 20px;
      height: 20px;
    }
  }

  .line {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    button {
      width: 16px;
      height: 16px;
      margin-left: 16px;
      background: transparent;
      border: 0;
      padding: 0;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &:hover {
      background-color: #e0eafc;
    }
  }
  .button-section {
    display: flex;

    button {
      @include button(150px, 36px);
    }
  }
}
