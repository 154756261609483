#submittedList {
  &,
  .nav-bar {
    padding-left: 50px;
    padding-right: 50px;
  }
  .content-table {
    margin-top: 30px;
    .header-row,
    .data-row {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      align-items: stretch;
      .actions {
        justify-self: center;
        width: 120px;
      }
      .links {
        width: 150px;
      }
    }
    .header-row {
      box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.08);
      background: #ffffff;
      border-radius: 4px;
      align-items: center;
      font-weight: 600;
      padding: 16px 0;
      h5 {
        margin: 0;
      }
    }
    .content-data {
      margin-top: 12px;
      .data-row {
        font-weight: 500;
        padding: 10px 0;
        margin-bottom: 10px;
        @include text16;
        &:hover {
          background-color: #f4f8ff;
        }
      }
      .name {
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
          width: calc(100% - 90px);
        }
      }
      .skills .skill:not(:last-of-type)::after {
        content: ", ";
        margin-right: 5px;
      }
      .actions {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        a img {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
      .education,
      .experience {
        div {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          width: auto;
        }
      }
      .links {
        display: flex;
        justify-content: flex-start;
        img {
          width: 25px;
          height: 25px;
          margin-right: 16px;
        }
      }
    }
  }
}

