@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

$sans-serif: "Inter", sans-serif;
$serif: "Cormorant Garamond", sans-serif;
$display: "Roboto Slab", sans-serif;

@mixin headingText {
  font-family: $sans-serif;
  font-weight: 700;
  font-size: 3.45vh;
  line-height: 1.375;
  letter-spacing: 0.02em;
}

@mixin text16 {
  font-size: 16px;
  line-height: 1.5; // line-height: 24px;
  letter-spacing: 0.02em;
}

@mixin text14 {
  font-size: 14px;
  line-height: 1.57; // line-height: 22px;
  letter-spacing: 0.02em;
}
