#description .main-content {
  .top-section {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;

    .left-section {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }

      .laterLogo {
        background: rgb(193, 154, 107);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        span {
          color: #ffffff;
          font-size: 28px;
          font-weight: 500;
        }
      }
    }

    .right-section {
      &>* {
        margin: 0 0 8px;
      }

      .links {
        display: flex;
        flex-direction: row;
        margin-bottom: 0;

        a {
          margin-right: 8px;
        }
      }
    }

    .button-section {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;

      .apply {
        @include button(125px, 36px);
        margin-left: 20px;

        &:hover {
          background-image: linear-gradient(#ffffff, #ffffff),
            linear-gradient(95.14deg,
              #0b53dd 15%,
              #85adf9 85%);
        }
      }
    }
  }

  .other {
    margin-top: 16px;
    margin-bottom: 24px;
    padding-left: 30px;
    font-weight: 500;

    .section-data {
      display: flex;
      align-items: center;
      min-height: 40px;
    }

    .label-text {
      margin-top: 0;
      text-transform: capitalize;
      margin-left: 8px;
      max-width: calc(100% - 32px);

      p {
        margin: 0;
      }
    }
  }

  // Paragraphs
  #descriptionTxt {
    padding-right: 48px;

    .section:not(:first-of-type) {
      margin-top: 32px;
    }

    .heading-title {
      height: auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #363636;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    label {
      padding-left: 28px;
      padding-right: 28px;
      border-radius: 4px;
      padding-top: 4px;
      height: 36px;
      margin-right: 10px;
      margin-top: 8px;
    }

    .heading-description {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #363636;
    }

    .label-text,
    .description-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #767676;

      * {
        font-size: 16px;
        line-height: 24px;
        color: #767676;
        margin-top: 8px;
      }
    }

    .skills-heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #363636;
      margin-top: 12px;
    }

    .skills-text {
      font-weight: 500;
      border: none;
      height: 28px;
      background: #f4f8ff;
      border-radius: 2px;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #363636;
      text-transform: capitalize;
    }
  }
}