#job_details_page {
  // background-color: #f4f8ff;
  padding-bottom: 20px;


    .data-section {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
      border-radius: 8px;
    }

    .toggle-section {
      z-index: 5;
      float: right;
      width: auto;
    }

    .applicant-table {
      margin-top: 20px;
    }

    .toggle-button{
      float: right;
    }
    .toggle-button,
    .toggle-profiles {
      padding: 1.5px 2px;
      border-radius: 4px;
      display: inline-flex;
      border-radius: 10px;
      background: rgba(225, 225, 238, 0.50);
      position: relative;
      button {
        background-color: transparent;
        display: inline-flex;
        align-items: center;
        border: 0;
        padding: 6px 10px;
        padding: 8.5px 18px;
        gap: 6px;
        height: 40px;
        color: var(--gray-gray-7, #5F6989);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: "Montserrat", sans-serif;
      }

      button.active {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(62, 72, 84, 0.10);
        color: var(--bright-greek-blue-8, #1369E9);
       
      }
    }

    .heading-row {
      display: flex;
      // justify-content: space-around;

    }

    .left-section {
      .type-dropdown-section {
        padding: 8px 0;
        border-bottom: 1px solid #9ccbe0;
        background: white;
        position: sticky;
        top: 0;
        z-index: 3;

        .type-dropdown-header {
          text-transform: capitalize;
          margin: 0;

          .toggle {
            background: transparent;
            border: 0;
            float: right;
            padding: 0;

            &.open svg {
              transform: scaleY(-1);
            }
          }
        }

        .type-dropdown-menu {
          position: absolute;
          width: 100%;
          top: 50px;
          background: #ffffff;
          box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
          border-radius: 4px;
          padding: 5px 0;
          z-index: 101;
          list-style-type: none;

          li {
            text-transform: capitalize;
            padding: 4px 8px;
            @include text14;
            font-weight: 500;

            &:hover {
              background-color: #f4f8ff;
            }

            &.disabled {
              pointer-events: none;
              opacity: 0.5;
            }
          }
        }
      }

      .list-item {
        font-weight: 500;
        padding: 10px;
        cursor: pointer;

        .actions {
          position: absolute;
          right: 0;
          width: 24px;
        }

        img {
          width: 40px;
          height: 40px;
          margin-right: 12px;
          object-fit: contain;
          display: inline-block;
        }

        .position {
          font-weight: 600;
          @include text16;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .company {
          @include text14;
          color: #767676;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .applicant-details {
          position: absolute;
          width: 275px;
          height: auto;
          padding: 10px;
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
          border-radius: 8px;
          z-index: 99;
          left: -60px;
          top: 75px;
        }
      }
    }

    .right-section .top-section {
      margin-top: 5px;

      .header {
        width: calc(100% - 136px);
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: none;

        .header-position {
          margin: 0;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .data-row {
      display: flex;
      flex-direction: row;
      // justify-content: space-around;
      // height: 80px;
      background: #ffffff;
      border-radius: 4px;
      align-items: center;

      // margin-top: 8px;
      // cursor: pointer;
      &:hover {
        background-color: #f4f8ff;
      }

      .data-item {
        // display: flex;
        // flex-direction: row;
        // align-items: center;

      }
    }

  @import "description";
  @import "job_analytics";
  @import "edit_description";
  @import "edit_analytics";
  @import "candidate_list";
  @import "hiring_pipeline";
  @import "no_jobs";
  @import "no_candidates";
  @import "job_evaluation";
  @import "job_actions";
  @import "assessment_plan";
  @import "assessment_invites";
  @import "assessment_scores";
  @import "invite_modal";
}