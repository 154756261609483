#botpage {
  .data-section {
    box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.08);
    background: #ffffff;
    border-radius: 4px;
    padding: 0 20px 20px;
  }
  .meeting-button {
    width: 196px;
    height: 50px;
    margin: 0 2.2vw;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: 1.4px solid #0b53dd;
    border-radius: 4px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    background: #0b53dd;
  }
  .criteria {
    display: inline-flex;
    justify-content: flex-end;
    float: right;
    align-items: center;
    align-content: center;
    margin: 0 16px;
    h3 {
      line-height: 32px;
      font-size: 20px;
      margin: 2.2vh 10px;
      cursor: pointer;
    }
    svg {
      cursor: pointer;
    }
  }

  .criteria-menu {
    position: absolute;
    width: 312px;
    height: auto;
    right: 2%;
    left: auto;
    top: 60px;
    text-align: left;
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
    border-radius: 4px;
    border: 0;
    padding: 10px 0;
    z-index: 10;
    .menu-item {
      padding: 12px;
      font-size: 16px;
      &:hover {
        background-color: #c5c5c5;
        font-weight: 600;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  .question-section {
    height: 52vh;
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .graph {
      width: calc(50% - 15px);
      height: 100%;
    }
    .question-answer-sec {
      width: calc(50% - 15px);
      height: 100%;
      .heading-sec {
        position: sticky;
        top: 0px;
        background: white;
        z-index: 2;
      }
      .question-div {
        overflow-y: scroll;
      }
      b {
        font-weight: 700;
      }
    }

    .button-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 0 20px;
      background: #f5f5f5;
      button {
       
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 1.4px solid #0b53dd;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        color: #0b53dd;
        & > span {
          top: -5px;
        }
      }

      .next{
        width: 160px;
        margin-right: 10px;
      }

      .ignore{
        border: none;
      }
    }

    .most-appropriate {
      // border: 1px solid #14AE5C;
      border-radius: 2px;
      margin-top: 20px;
      padding-bottom: 10px;

      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #14ae5c;
      }
    }

    .question {
      font-size: 16px;
    }

    .answer {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #767676;
      margin-top: 20px;
    }

    .acceptable {
      color: orange;
    }
  }

  .transcription-section {
    height: 30vh;
    width: 100%;
    margin-top: 30px;
    .heading-sec {
      position: sticky;
      top: 0px;
      background: white;
      z-index: 2;
    }
    .names {
      width: 320px;
      height: 30vh;
      .speaker {
        display: flex;
        align-items: center;
      }
      .col-xs-3 {
        width: 40px;
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .transcript {
      float: right;
      width: calc(100% - 350px);
      font-size: 16px;
      font-weight: 500;
      font-family: $sans-serif;
      height: 100%;
      overflow-y: scroll;
      color: #363636;
      .speaker {
        font-weight: 700;
        color: black;
        &::after {
          content: " :";
          margin-right: 10px;
        }
      }
      .time {
        float: right;
      }
    }
  }

  .heading-sec {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #363636;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
