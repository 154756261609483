.candidate-pages {
  &#dashboard {
    overflow: auto;
    .list-length {
      font-family: $sans-serif;
      color: #426bba;
      font-size: 16px;
      line-height: 24px;
      position: fixed;
      margin-top: 20px;
    }
    #job-list {
      float: right;
      width: calc(100% - 328px - 20px);
      .job-post {
        background: #fdfeff;
        box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.02);
        border-radius: 12px;
        min-height: 208px;
        margin-top: 20px;
        padding: 24px 28px;
        .text-section {
          width: calc(100% - 96px - 156px);
        }
        .right {
          float: right;
          text-align: right;
          .bookmark {
            margin-left: 24px;
            display: inline-block;
          }
          .status {
            display: inline-flex;
            .status-text {
              font-size: 14px;
              line-height: 22px;
            }
            img {
              margin-right: 5px;
            }
          }
        }
      }
      a {
        color: #363636;
      }
      .section-1 {
        border-bottom: 1.4px solid #e1edff;
        padding-bottom: 16px;
        height: 42px;
        * {
          height: 24px;
        }
      }
      .section-2 {
        display: flex;
        align-items: center;
        font-family: $sans-serif;
        color: #363636;
        margin-top: 18px;
      }
      .imageLogo {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        img {
          width: inherit;
          height: inherit;
          border-radius: inherit;
        }

        .laterLogo{
          background: rgb(193, 154, 107);
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: inherit;
          span{
            color: #ffffff;
            font-size: 28px;
            font-weight: 500;
          }
        }
      }
      .button-section {
        display: flex;
        align-self: flex-end;
        flex-direction: row-reverse;
        .apply {
          // @include button(156px, 44px);
          background: none;
          border: none;

          span{
            color: #0b53dd;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.4;
          }
          
        }
      

      }
      .section-1,
      .text-section {
        img {
          width: 25px;
          height: 20px;
          position: relative;
          top: 2px;
        }
      }
      h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin-top: 0;
      }
      h5 {
        font-size: 14px;
        line-height: 22px;
        margin: 0;
      }
      .section-2 h5 {
        color: #767676;
        display: inline-flex;
        margin-top: 6px;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
      .apply span.label,
      .company_name {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }
    }
  }
}
