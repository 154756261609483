.landing-page {
  // padding-left: 100px;
  z-index: 11;

  // &#startPage {
  //   width: calc(100% - 250px);
  // }

  h1 {
    font: normal 600 28px Montserrat;
    color: #000000;
  }

  h2 {
    font: normal 600 24px Montserrat;
  }

  h3 {
    font: normal 600 22px Montserrat;
  }

  h4 {
    font: normal 600 18px Montserrat;
    color: #252E4B;
  }

  .gif-section {
    height: calc(100vh);
    margin-top: -85px;
    background: #F3F5F9;
    left: -40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 78%;
      height: 433px;
      object-fit: contain;
    }
  }
  .startpage-data-section{
    background: #FFFFFF;
    height: calc(100vh);
    margin-top: -85px;
    right: -26px;
    padding: 20px 75px 20px 55px;


    img{
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    .instructions{
      margin-top: 32px;
    }
  }

  .data-table {
    background: #FFFFFF;
    border: 1px solid #DADAE4;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 12px;


    .data-row {
      padding: 13px 0px 17px 0px;

      .border-mid {
        border-right: 1px solid#D9D9D9;
      }

      .data-col {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        .header {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #252E4B;
        }

        .text {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #5F6989;
        }


      }

      span {
        font: normal 500 16px Montserrat;
      }
    }

    .duration-raw {
      height: 44px;
      background: #F9F9F9;
      border-top: 1px solid #E1E1EE;
      border-radius: 0px 0px 12px 12px;
      display: flex;
      align-items: center;
      padding-left: 20px;

      .duration-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #5F6989;
        margin-left: 9px;
      }

      .duration-time {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #252E4B;
        margin-left: 7px;
      }
    }


  }


  .section-data {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-top: 17px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */


      color: rgba(37, 46, 75, 0.9);


      svg {
        margin-right: 17px;
      }
    }
  }

  &#startPage {
    button {
      @include button(auto, 48px, $border: #0b53dd);
      background: #1369E9;
      margin-top: 20px;
      float: left;
      padding: 8px 16px;
      font-weight: 600 !important;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 8px;
    }

    .start-interview-btn {
      color: #FFFFFF;
    }
  }

  &#endPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .data-section {

      .header-sec {
        background: #F9F9F9;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      // width: 570px;
      .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #5F6989;
      }

      .details {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        margin-top: 34px;
        padding: 8px 24px 11px 18px;
        height: 51px;
        border-radius: 12px 12px 0px 0px;



        .duration-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #5F6989;
          margin-left: 9px;
        }

        .duration-time {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #252E4B;
          margin-left: 7px;
        }
      }

      .lower-sec {
        background: #FFFFFF;
        padding: 48px 80px 42px 80px;

        .data-row {
          padding: 13px 0px 17px 0px;
          border: 1px solid #DADAE4;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 12px;

          .border-mid {
            border-right: 1px solid#D9D9D9;
          }

          .data-col {
            display: flex;
            flex-direction: column;
            padding-left: 20px;

            .header {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #252E4B;
            }

            .text {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #5F6989;
            }


          }

          span {
            font: normal 500 16px Montserrat;
          }
        }
      }
    }

    a.logo {
      position: relative;
      width: 140px;
      height: 28px;
      margin-top: -40px;
      margin-bottom: 30px;
    }

    .feedback-section {
      border-top: 1px solid #E1E1EE;
      margin-top: 40px;
      padding-top: 40px;

      .star {
        width: 42.8px;
        height: 42.8px;
        border: 1px solid #eff0f2;
        margin: 0 18px 0 0;
        padding: 0;
        background: white;
        border-radius: 8px;

        &.select {
          border-color: transparent;
          background: #ff9500;

          svg path {
            fill: white;
          }
        }
      }
    }

    label {
      margin-top: 32px;
    }

    button {
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #E1E1EE;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #252E4B;
      margin-bottom: 30px;
    }
  }
}