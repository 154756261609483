.candidate-pages {
  .filter {
    position: fixed;
    width: 328px;
    height: calc(100vh - 136px);
    margin-top: 20px;
    overflow: hidden;
    padding: 16px 20px;
    background: #fdfeff;
    border-radius: 12px;

    .no-scroller {
      overflow-y: scroll;
      width: calc(100% + 36px);
      height: calc(100% - 38px - 30px);
      padding-bottom: 38px;
      padding-right: 16px;
    }

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 16px;
    }

    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-transform: capitalize;
    }

    label {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #767676;
      text-transform: capitalize;
    }

    ul {
      margin-bottom: 0;

      li {
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    .checker {
      border: 1px solid #767676;
      border-radius: 1px;
      margin: 0 14px 0 0;
      height: 24px;
    }

    .filter-option {
      border-top: 1.4px solid #e1edff;
      padding: 8px 0;
    }

    .button-section {
      background: #fdfeff;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 16px;
      display: flex;
      width: 100%;
      justify-content: space-between;

      button {
        @include button(calc(50% - 16px), 36px);
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        border-radius: 4px;

        &.clear-btn {
          background: #c5c5c5;
          color: #363636;
        }
      }
    }
  }

  #description-analytics {
    .data-section {
      background: #f4f8ff;
      box-shadow: 0px 2px 12px rgba(0, 27, 75, 0.04);
      border-radius: 8px;
      padding: 20px;
      margin-top: 20px;

      .graph_exp {
        border-left: 1px solid #85adf9;
        height: 100px;
      }

      .section-header {
        font-family: $sans-serif;
        padding-bottom: 10px;
        line-height: 24px;
        border-bottom: 1px dashed #85adf9;
        margin-bottom: 20px;

        .labels {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        label {
          width: 12px;
          height: 12px;
          border-radius: 1px;
          margin-top: 4px;
        }

        .expected {
          background: #0b53dd;
        }

        .candidate-score {
          background: #6b9bf8;
        }

        h4 {
          font-weight: 600;
          font-size: 14px;
          color: #363636;
        }

        h6 {
          font-weight: 400;
          font-size: 10px;
          color: #001b4b;
          margin-left: 4px;
        }
      }

      .section-degree {
        font-family: $sans-serif;
        line-height: 24px;
        border-bottom: 1px dashed #85adf9;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }

      .divider {
        border-top: 1px dashed #85adf9;
        opacity: 1;
        background-color: transparent;
      }

      .tag,
      .icon {
        background-color: #e1edff;
        padding: 10px;
        border-radius: 4px;
      }

      .icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .award-text {
        font-weight: 500;
        font-size: 10px;
        color: #363636;
      }

      .tag {
        font-family: $sans-serif;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 12px;
        // line-height: 25px;
        color: $color3;
        margin-left: 10px;
      }

      .title {
        font-family: $sans-serif;
        margin-left: 10px;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 10px;
        color: #363636;
      }

      &.salary .title {
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.02em;
      }

      &.education .title {
        font-size: 16px;
        line-height: 24px;
      }

      .chart-caption {
        font-family: $sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #363636;
        display: flex;
        justify-content: space-evenly;
      }
    }

    .right-section-chart {


      .col-xs-6 {
        width: calc(50% - 32px);
        margin: 16px;
      }



      .experience {
        height: 300px;

        span {
          background: #ffffff;
          border-radius: 13px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    .chart {
      label {
        position: absolute;
        font-family: $sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 30px;
        top: 75px;
        color: #363636;
      }

      h4 {
        font-weight: 500;
        font-size: 12px;
        color: #001b4b;
      }
    }

    svg rect[fill="#ffffff"] {
      fill: transparent;
    }

    .skills {
      .chart {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      svg {

        rect,
        text {
          fill: transparent;
        }

        path {
          stroke: transparent;
        }
      }
    }

    .key-skills {
      .labels {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      label {
        width: 12px;
        height: 12px;
        border-radius: 1px;
        margin-top: 4px;
      }

      .expected {
        background: #24cf9c;
      }

      .candidate-score {
        background: #dbec1b;
      }

      h4 {
        font-weight: 600;
        font-size: 14px;
        color: #363636;
      }

      h6 {
        font-weight: 400;
        font-size: 10px;
        color: #001b4b;
        margin-left: 4px;
      }
    }

    .key-skills .section-data {
      svg rect {

        &[fill="#cccccc"]:not(:first-of-type),
        &[fill="#ebebeb"] {
          fill: transparent;
        }

        &[fill="#cccccc"]:first-of-type {
          fill: #001b4b;
        }
      }
    }

    .experience .section-data {

      svg rect[fill="#ffffff"],
      svg rect[fill="#e6e6e6"] {
        fill: transparent;
      }

      svg rect[stroke="#9a9a9a"],
      svg path {
        stroke: none;
      }
    }

    .job-type-section {
      padding-left: 12px;
    }
  }

  .messages {
    padding-bottom: 40px !important;

    #job-list {
      .job-post {
        background: $color1;
        box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.02);
        border-radius: 16px;
        height: 208px;
        margin-bottom: 20px;
        padding: 20px;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-top: 0;
    }

    h5,
    .company_name {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      margin-top: 0;
    }

    .section-2 h5 {
      color: #767676;

      svg {
        margin-right: 5px;
      }
    }

    .apply span,
    .status-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    .chat {
      border: 1px solid $color1;
      padding: 8px;

      .chat-section {
        height: calc(100vh - 176px);
        overflow-y: auto;
      }

      input {
        @include input-field(calc(100% - 120px), 40px);
        margin-right: 20px;
      }

      button {
        @include button(100px, 40px);
        color: white;
      }
    }
  }

  &#applied-jobs,
  &#saved-jobs {
    .headingText {
      font-weight: 600;
      font-size: 20px;
      margin-left: 20px;
      letter-spacing: 0.02em;
    }

    .header-row,
    .job-post {

      &>div,
      &>h4 {
        width: 18.7777777778%;

        &:first-of-type {
          width: 25%;
        }
      }
    }

    .header-row {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0 20px 8px;
      border-bottom: 2px solid #f4f8ff;

      .header {
        font-size: 14px;
        line-height: 16px;
        margin: 0;
      }
    }

    .job-post {
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
      border-radius: 4px;
      height: 108px;
      margin-top: 20px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }

  &#create-profile .item,
  #edit-profile .modal {
    form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;

      .col-md-6 {
        width: 48%;
      }

      select,
      input[type="text"],
      input[type="date"] {
        @include input-field(654px, 44px);
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        font-family: $sans-serif;
        letter-spacing: 0.02em;
        color: $color5;
      }

      input[type="file"]::-webkit-file-upload-button {
        visibility: hidden;
      }

      select,
      input[type="text"],
      input[type="date"],
      textarea {
        width: 100%;
        border: 1px solid #CBD5E0;
        padding: 8px;
        border-radius: 8px;
        font-size: 16px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);

        /* Hover state */
        select,
        input[type="text"],
        input[type="date"],
        textarea:hover {
          border-color: #1369E9;
        }

        /* Focus state */
        select,
        input[type="text"],
        input[type="date"],
        textarea:focus {
          border-color: #1369E9;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
        }

        /* Placeholder styles */
        select,
        input[type="text"],
        input[type="date"],
        textarea::placeholder {
          font-size: 16px;
          font-weight: normal;
          color: #5F6989;
        }
      }

      [disabled],
      :disabled {
        pointer-events: none;
        opacity: 0.6;
        cursor: none;
      }

      label,
      textarea {
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #767676;
        margin-top: 20px;
      }

      textarea {
        height: 108px;
        margin-top: 0;
      }

      .exp-data,
      .edu-data {
        border-bottom: 1px solid #7fa9f8;
        padding: 16px 0;

        p {
          word-break: break-word;
        }
      }
    }

    &#upload-resume {
      input[type="file"] {
        display: none;
      }

      label {
        width: 100%;
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #767676;
      }

      p {
        text-align: center;
        margin-bottom: 0;
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #767676;
      }

      .upload-section {
        display: flex;
        border: 1px solid $color3;
        border-radius: 8px;
        height: 124px;
        padding: 10px 0;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .parser-section {
        #parser {
          width: 24px;
          height: 24px;
        }

        label {
          width: auto;
          position: relative;
          top: -6px;
          left: 16px;
        }
      }

      input[type="file"] {
        @include button(226px,
          44px,
          $text-size: 16px,
          $text-height: 26px,
          $text-spacing: 0.01em);
        margin: 0 auto;
        padding: 0;

        &::before {
          content: attr(title);
          height: 40px;
          width: 222px;
          position: relative;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px double transparent;
          padding: 7px;
        }

        &:hover,
        &:active {
          border-color: #0b53dd;
          background: white;

          &::before {
            outline: 0;
            box-shadow: 1px 2px 6px rgba(39, 69, 122, 0.08);
          }
        }
      }
    }

    #skills {

      .auto-complete-list {
        display: flex;
        flex-direction: column;
        height: 250px;
      }

      .input-section-left {
        padding-right: 10px;
      }

      .input-section-right {
        padding-left: 10px;
      }

      .criteria-header {
        display: flex;
        align-items: center;

        .remove-criteria {
          cursor: pointer;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    #personal {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .image-section {
        width: 156px;
        margin-top: 20px;

        label {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          color: #0b53dd;

          svg {
            margin-right: 10px;
          }
        }

        input[type="file"] {
          display: none;
        }

        .img-responsive {
          width: 100%;
          height: 156px;
          border: 1px solid;
          border-radius: 50%;
          object-fit: contain;
        }
      }

      .text-section {
        width: calc(100% - 220px);
      }
    }

    #links {
      .row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .add {
          width: 60px;
        }

        .cancel {
          background: transparent;
          border: 0;
        }
      }
    }

    #experience,
    #education {
      .right-section {
        text-align: right;
      }

      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      .checkbox-input {
        display: flex;
        float: right;

        input {
          margin-top: 20px;
          margin-right: 10px;
          width: 20px;
          height: 20px;
          background: #ffffff;
          border: 1px solid #363636;
          border-radius: 1px;
        }
      }
    }
  }
}