#profile-page {
  // Combined profile page styling


  .main-content {
    .heading-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 116px;


      .profile-page-heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        margin: 0 106px 0 0;
      }

      .button-section {
        @include text16;

        .button {
          padding: 0 36px 12px 2px;
          background: none;
          border: none;
          border-bottom: 2px solid #85adf9;

          &:hover {
            border-bottom-color: #5988df;
          }

          &.active {
            border-bottom-color: $color3;
          }
        }
      }
    }

    select,
    input[type="text"],
    input[type="date"],
    textarea {
      width: 100%;
      border: 1px solid #CBD5E0;
      padding: 8px;
      border-radius: 8px;
      font-size: 16px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);

      /* Hover state */
      select,
      input[type="text"],
      input[type="date"],
      textarea:hover {
        border-color: #1369E9;
      }

      /* Focus state */
      select,
      input[type="text"],
      input[type="date"],
      textarea:focus {
        border-color: #1369E9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
      }

      /* Placeholder styles */
      select,
      input[type="text"],
      input[type="date"],
      textarea::placeholder {
        font-size: 16px;
        font-weight: normal;
        color: #5F6989;
      }
    }


    label {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #767676;
      margin-top: 20px;
    }

    textarea {
      resize: none;
      border-radius: 8px;
    }

    .edit-candidate {
      .start {
        padding-left: 0px;
      }

      .end {
        padding-right: 0px;
      }

      h5 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
      }
    }

  }

  .details-section {
    margin-top: 28px;
    padding-right: 0px;
  }

  .data-section {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 27, 75, 0.06);
    border-radius: 8px;
    padding: 20px;
    min-height: 144px;

    .section-header {
      font-family: $sans-serif;
      line-height: 24px;
      border-bottom: 1px dashed #85adf9;
      margin-bottom: 20px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h4 {
        font-weight: 600;
        font-size: 18px;
        color: #363636;
      }
    }
  }

  .toggle-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
  }

  .toggle-button {
    border: 1px solid #c3c7df;
    border-radius: 4px;
    display: inline-block;
    right: 0;
    position: relative;

    button {
      background-color: transparent;
      border: 0;
      padding: 6px 10px;
      @include text14;
      font-weight: 500;
    }

    button.active {
      background-color: #e4efff;
    }
  }

  .edit-button {
    background: none;
    border: none;
    right: 0;
  }

  .revert-button {
    right: 60px;
  }

  .done-button {
    right: 100px;
  }

  #profile-analytics .analytics-dashboard {

    .data-section {
      background: #ffffff;
      box-shadow: none;
      border-radius: 8px;
      padding: 0;
      min-height: 144px;
      margin-bottom: 28px;

      .section-header {
        font-family: $sans-serif;
        line-height: 24px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #363636;
        }

        .edit {
          margin: 10px 0;
          border: none;
          background: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #363636;
          float: right;
          width: auto;
          padding-right: 0px;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &[data-tooltip]::after {
            top: 20px;
            left: 20px;
          }

          svg {
            width: 24px;
            height: 24px;
            margin-right: 6px;
            margin-left: 12px;
          }

          .divider {
            border-top: 1px dashed #85adf9;
            opacity: 1;
            background-color: transparent;
          }
        }
      }

      .tag,
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 0 20px;
        text-transform: capitalize;
      }
    }

    .key-skills-chart {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin-top: -20px;

      .section-header {
        border-bottom: 0;
        margin-bottom: 16px;
        align-items: center;
        background-color: #e0ebff;
        padding: 0 16px;

        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
      }

      .section-data {
        margin-bottom: 16px;

        .row {
          display: flex;
          float: right;
          padding-right: 20px;
          align-items: center;

          .labels {
            width: 20%;
            text-align: right;
            margin: 0;
            font-size: 16px;
            line-height: 28px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .level {
            width: calc(80% - 60px);
            margin: 0 20px;
          }
        }
      }
    }

    .experience .section-data {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .experience-graph-heading {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #363636;
      }

      div {
        float: left;
      }
    }
  }


  #edit-skills {
    margin-top: -20px;

    .section {
      margin: 0 0 20px;
      padding: 20px 10px 10px;
      background-color: transparent;

      &:hover {
        background-color: #f3f7ff;
      }
    }

    .input-section label,
    .line span {
      @include text16;
    }

    input {
      height: 36px;

      &[type="number"] {
        padding-right: 0;
      }
    }

    .section-header {
      border: 0;
      align-items: center;

      input,
      .value {
        font-size: 18px;
        line-height: 36px;

        &.col-xs-10 {
          width: calc(83% - 70px);
        }
      }

      .icon,
      .icon svg {
        width: 16px;
        height: 16px;
      }


    }

    .line {
      padding: 10px 5px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      z-index: 1;

      button {
        width: 16px;
        height: 16px;
        margin-left: 16px;
        background: transparent;
        border: 0;
        padding: 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &:hover {
        background-color: #e0eafc;
      }
    }

    .button-section {
      display: flex;

      button {
        @include button(150px, 36px);
      }
    }
  }

  .add-button,
  .edit {
    margin: 10px 0;
    border: none;
    background: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
    float: right;
    width: auto;
    padding-right: 0px;
    display: flex;
    position: relative;
    right: 0;
    bottom: 0;

    &[data-tooltip]::after {
      top: 20px;
      left: 20px;
    }

    svg {
      margin-right: 6px;
      margin-left: 12px;
    }

    .divider {
      border-top: 1px dashed #85adf9;
      opacity: 1;
      background-color: transparent;
    }
  }

  // Profile page + Edit Profile
  #edit-profile {

    .edit-button {
      position: absolute;
      right: 0;
      top: -70px;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #363636;

      svg {
        margin-right: 10px;
      }
    }

    // Sections CSS
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #363636;
        // width: calc(100% - 84px);
        float: left;
      }
    }

    .data-section {
      &:not(:first-of-type) {
        margin-top: 28px;
      }

      // Common font CSS
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #454545;
        margin-top: 4px;
        text-transform: lowercase;
      }

      .title::first-letter {
        text-transform: capitalize;
      }

      .duration {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #3e3e3e;
        margin-top: 4px;
      }

      .sub-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #454545;
      }

      .sub-title::first-letter {
        text-transform: capitalize;
      }


      .description {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #636363;
        margin-top: 12px;
      }

      .description::first-letter {
        text-transform: capitalize;
      }


      .grade {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #373737;
      }
    }

    .personal-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .profile-pic {
        width: 186px;
        height: 186px;

        img {
          border-radius: 50%;
          border: $border;
          object-fit: cover;
          background-color: white;
          display: inline-block;
          height: 100%;
        }
      }

      .personal-info {
        display: flex;
        flex-direction: column;

        .profile-name {
          font-weight: 600;
          font-size: 24px;
          letter-spacing: 0.02em;
          color: #363536;
          margin-left: 28px;
        }

        .profile-position {
          font-weight: 500;
          font-size: 16px;
          color: #363636;
          margin-top: 16px;
          margin-left: 28px;
        }

        .profile-position::first-letter {
          text-transform: capitalize;
        }

        .profile-about {
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #636363;
          margin-left: 28px;

        }

        .profile-about::first-letter {
          text-transform: capitalize;
        }
      }

      .resume-modal-button {
        border: 0;
        background: none;
        font-weight: 600;
        @include text16;
        color: $color3;
        margin-top: 16px;
        text-align: left;
        margin-left: 28px;

        span {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

      }
    }

    .skills-section .section-data {
      h6 {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #363636;
        margin: 0;
      }

      .tag {
        padding: 4px 16px;
        border: 1px solid #E1E1EE;
        border-radius: 8px;
        min-height: 36px;
        margin-right: 16px;
        display: inline-block;
        margin-top: 16px;
        text-transform: capitalize;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          letter-spacing: -0.005em;
          color: #363636;
        }
      }
    }

    .education-section,
    .experience-section {
      .logo-section {
        width: 100px;
        height: 100px;
        margin-right: 42px;

        svg {
          width: 100%;
          height: auto;
        }
      }

      .data-row {
        padding: 20px 0px 16px 0px;
        display: flex;
        align-items: center;

        &:not(:first-of-type) {
          border-top: 1px solid #d0dffb;
        }
      }

      .date-row,
      .edit-row {
        display: flex;
        justify-content: space-between;
      }

      .date-row {
        align-items: flex-start;
      }

      .edit-row {
        align-items: flex-end;
      }
    }

    .project-section {
      .website-link {
        padding: 10px 0;
        margin-bottom: 30px;
        border-bottom: 1px solid #d0dffb;

        .label {
          @include text16;
          text-align: left;
          margin-top: 4px;
        }

        .link {
          margin-top: 4px;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-decoration: underline;
          color: $color3;

          span {
            text-decoration: underline;
            margin-top: 4px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-decoration: underline;
            color: $color3;
          }
        }
      }

      .data-row {
        .img-responsive {
          height: 224px;
          box-shadow: 0px 0px 8px rgba(54, 91, 155, 0.06);
          border-radius: 4px;
        }
      }

      #project {
        input[type="file"] {
          border: 0;
          color: transparent;
          width: 0;
          height: 0;
          padding: 0;
        }

        .file-upload-section {
          * {
            margin: 0;
          }

          label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $color3;
            border-radius: 8px;
            padding: 16px;
          }
        }
      }
    }
  }

  // Resume Analytics
  #profile-analytics {
    .resume {
      margin: 50px 0 0;
    }

    .data-section {
      .icon {
        // background-color: #e1edff;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: inline-block;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      .tag,
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: 0 20px;
        text-transform: capitalize;
      }

      &.other .section-data {
        min-height: 30px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
      }

      .chart-caption {
        font-family: $sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #363636;
        display: flex;
        justify-content: space-evenly;
      }
    }

    .right-section>.col-xs-12 {
      width: calc(100% - 32px);
      margin: 0 0 28px 28px;
    }

    .skills .chart {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .key-skills {
      display: flex;
      flex-direction: column;
      align-items: center;

      h4 {
        font-size: 18px;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }

      span {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 18px;
      }
    }

    .experience .section-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      div {
        float: left;
      }
    }
  }
}

#job-preferences {

  .edit-button {
    position: absolute;
    right: 0;
    top: -70px;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #363636;

    svg {
      margin-right: 10px;
    }
  }

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #363636;
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #363636;
  }

  p1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #767676;
  }

  .section-1 {
    padding-bottom: 32px;
    border-bottom: 1px solid #D0E3FF;

  }

  .section-2 {
    padding-top: 15px;
    padding-bottom: 32px;
    border-bottom: 1px solid #D0E3FF;

  }

  .section-3 {
    padding-top: 15px;
    padding-bottom: 32px;
  }

  form {
    .section {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #D0E3FF;
      padding-bottom: 20px;

      h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #363636;
        margin-top: 20px;
      }

      .tab-section {
        // margin-top: 8px;

        .tag {
          display: inline-flex;
          font-weight: 500;
          margin-top: 8px;
          margin-right: 10px;
          font-size: 12px;
          letter-spacing: 0.005em;
          color: #363636;
          background: #F4F8FF;
          border-radius: 2px;
          padding: 6px 12px 6px 12px;

          .remove {
            margin-left: 10px;
          }
        }
      }

      .col-md-6 {
        width: 48%;
      }

      select,
      input[type="text"] {
        @include input-field(654px, 44px);
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        font-family: $sans-serif;
        letter-spacing: 0.02em;
        color: $color5;
      }

      input[type="checkbox"] {
        margin-right: 10px;
      }

      select,
      input[type="text"],
      input[type="date"],
      textarea {
        width: 100%;
        border: 1px solid $color3;
        border-radius: 8px;
      }

      [disabled],
      :disabled {
        pointer-events: none;
        opacity: 0.6;
        cursor: none;
      }

      label {
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #767676;
        margin-top: 20px;
      }

      ul {
        position: absolute;
        z-index: 100;
        width: 100%;
        max-width: 500px;
        max-height: 400px;
        overflow-y: auto;
        top: 90px;
        border-radius: 4px;
        background: white;
        box-shadow: 0 8px 8px 0 rgb(0 0 0 / 32%);
        padding: 20px;
      }

      li {
        list-style-type: none;
      }

      li:hover {
        background-color: #e1edff;
      }
    }
  }

  .preserence-button-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px
  }

  .update-preserence-button {
    @include button(128px, 36px);
    right: 0;
  }

}


#newexp {

  display: flex;
  align-items: center;


  svg {
    margin-left: 20px;
  }



  .line {
    width: 100px;
    border: 2px solid #85adf9;
  }

}

#custom-exp {
  margin-top: 40px;

  .points {
    display: flex;
    justify-content: space-between;


    span {
      width: 24px;
      height: 24px;
      background-color: #07bc0c;
      border-radius: 50%;

    }


    p {
      font-size: 10px;
    }
  }

  .company-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

  }

  p {
    font-size: 10px;
    width: 40px;

  }

  .line {
    border: 2px solid #07bc0c;
    margin-top: -13px;
  }

  .role {
    margin-top: 40px;

    .line {
      border: 2px solid #e74c3c;
      margin-top: -13px;
    }

    p {
      // font-size: 1.75vh;
    }

    span {
      background-color: #e74c3c;
    }
  }

  .company-points {
    display: flex;
    justify-content: space-around;
    margin-top: -68px;

  }



}