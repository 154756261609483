
#send-email-modal{
  .modal-dialog {
    width: 1000px;
  }
}
#add-Url-candidate-modal{
  .modal-dialog {
    width: 600px;
  }
}

#invite-modal {
  .modal-dialog {
    width: 428px;
  }
  .modal-content {
    padding: 24px 28px;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: #252e4b;
      }
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #5f6989;
      }
    }

    .input-sec {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      .warning,
      label {
        // font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #252e4b;
      }
      .warning {
        color: red;
      }
      input[type="email"] {
        padding-left: 36px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #e1e1ee;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'%3E%3Cg clipPath='url(%23clip0_1333_123326)'%3E%3Crect x='0.727539' y='0.831249' width='14.5455' height='12.6' rx='3' stroke='%235F6989'/%3E%3Cpath d='M0.727539 3.63125L5.55989 7.35216C6.9983 8.45974 9.00223 8.45974 10.4406 7.35216L15.273 3.63125' stroke='%235F6989' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1333_123326'%3E%3Crect width='16' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 10px center;
      }
      select {
        height: 40px;
        background: #ffffff;
        border: 1px solid #e1e1ee;
        border-radius: 8px;
        margin-bottom: 32px;
      }
      .email {
        margin-bottom: 32px;
      }
      .tags {
        margin: 12px 0 0;
        .email-tag {
          padding: 5px 6px;
          height: 28px;
          background: #eff0f2;
          border: 1px solid #eff0f2;
          border-radius: 5px;
          display: inline-flex;
          align-items: center;
          flex-wrap: nowrap;
          flex-direction: row;
          .label {
            // font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #16182f;
            padding: 0;
            margin-right: 8px;
          }
          button {
            border: 0;
            background: transparent;
            margin: 0;
            width: 14px;
            height: 14px;
            padding: 0;
            svg {
              width: 14px;
              height: 14px;
              position: relative;
              display: block;
            }
          }
        }
      }
      .expiry {
        margin-bottom: 32px;
        label{
            margin-right: 16px;
            &.unit{
                margin-left: 16px;
            }
        }
        input{
            height: 40px;
            width: 100px;
        }
      }
      .buttons button {
        &[type="submit"] {
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          width: 100%;
          height: 40px;
          background: #1369e9;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 8px;
          border: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          &[disabled]{
            opacity: 0.4;
          }
        }
        &[type="cancel"] {
          justify-content: center;
          align-items: center;
          padding: 0;
          margin-top: 22px;
          width: 100%;
          height: 24px;
          border: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #16182f;
          background: transparent;
        }
      }
    }
    .cancel-button {
      background: none;
      position: absolute;
      border: none;
      top: -16px;
      right: -16px;
      width: 20px;
      height: 20px;
      padding: 0;
      svg {
        width: 20px;
        height: 20px;
        position: relative;
        display: block;
      }
    }
  }
}
