// Color Pallete
$white: #f4f8ff;
$black: #00171f;
$color1: #e1edff;
$color2: #85adf9;
$color3: #0b53dd;
$color4: #001b4b;
$color5: #363636;
$color6: #767676;
$color7: #c5c5c5;

$navbar-height: 68px;
$sidebar-width: 80px;

$border: 2px solid #909090;
$light-border: 2px solid #a1a1a1;

// Implement: @include button(60px);
@mixin button(
  $width,
  $height,
  $radius: 4px,
  $border: transparent,
  $fill-color: #ffffff,
  $gradient-color-left: #85adf9,
  $gradient-color-right: #0b53dd,
  $text-size: 14px,
  $text-height: 1.4,
  $text-weight: 500,
  $text-spacing: -0.005em,
  $text-color: #0b53dd
) {
  height: $height;
  width: $width;
  position: relative;
  border-radius: $radius;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px double $border;
  padding: 7px;
  background-image: linear-gradient($fill-color, $fill-color),
    linear-gradient(
      95.14deg,
      $gradient-color-left 15%,
      $gradient-color-right 85%
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  &,
  * {
    letter-spacing: $text-spacing;
    color: $text-color;
    font-weight: $text-weight;
    font-size: $text-size;
    line-height: $text-height;
  }
}

@mixin input-field($width, $height) {
  width: $width;
  height: $height;
}
