.main-body .auth-pages {
  padding: 0;
  width: 100%;
  height: 100vh;
  background: #f4f8ff;

  .nav-bar {
    background: transparent;
  }

  main {
    height: 100%;
    max-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .sub-container {
      max-width: 100%;
      height: auto;
      width: 900px;
      min-height: 550px;
      max-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 32px rgba(4, 61, 171, 0.08);
      border-radius: 20px;
      padding: 20px 4%;
      background-color: white;
    }

    .logo {
      width: 100%;
      margin-bottom: 8.62vh;
      object-position: left;

      &,
      img {
        height: 4.2vh;
        object-fit: contain;
      }
    }
  }

  .headingText {
    @include headingText;
    color: #2d3542;
  }

  .subHeadingText,
  .registerOption,
  .joinUsBtn {
    @include text16;
    color: #58595b;
  }

  .input-wrapper {
    margin-top: 3vh;

    input{
      border: 1px solid var(--gray-gray-5, #DADAE4);
      background: var(--gray-gray-1, #FFF);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04) ;
    }

    .input {
      @include input-field(100%, 6.89vh);
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 2vh;
      line-height: 32px;
      letter-spacing: 0.2px;
      border-radius: 8px;
    }
  }

  .forgot-password {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #5F6989;
    margin-top: 8px;
    cursor: pointer;
    justify-content: space-between;
  }

  .signInBtn {
    @include button($width: 100%,
      $height: 6.89vh,
      $radius: 8px,
      $text-size: 2.16vh,
      $text-height: 1.6,
      $text-spacing: 0.02em);
  }

  .buttons-section {
    width: 100% !important;

    .google-button {
      @include button($width: 100% !important,
        $height: 6.89vh,
        $radius: 8px !important,
        $border: #c5c5c5 !important,
        $fill-color: #fff !important,
        $text-color: #2e2e2e !important,
        $text-size: 2.16vh,
        $text-height: 1.6,
        $text-spacing: 0.02em);
      box-shadow: none !important;
      display: inline-flex;

      div {
        display: flex;
        padding: 0 !important;
      }

      svg {
        transform: scale(1.25);
        margin-right: 14px;
      }

      &:not(:hover) svg path:not([fill="none"]) {
        fill: #363636;
      }
    }
  }

  .line {
    text-align: center;
    margin: 20px 0 32px;

    span {
      padding: 0 25px;
      background-color: white;
      z-index: 2;
      position: relative;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      top: 10px;
      background-color: black;
    }
  }

  .registerOption {
    margin: 28px 0 0;
    display: flex;

    .joinUsBtn {
      border: 0;
      color: #1a5ad7;
      padding: 0 0 0 7px;
      font-size: 16px;
      background-color: transparent;
      display: flex;
      align-items: center;

      svg{
        // fill: #1a5ad7;
        stroke: #1a5ad7;
        margin-right: 10px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .show-pwd {
    position: absolute;
    margin: 2.3vh 0;
    right: 16px;

    svg {
      height: 2.369vh;

      &:hover,
      &:active,
      &.active,
      &:focus {
        path {
          stroke: url(#gradient);
          stroke-width: 3;
        }
      }
    }
  }
}

@import "login";