#profile-analytics,
#description .analytics-dashboard {
  .data-section {
    background: #ffffff;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    min-height: 144px;
    margin-bottom: 28px;

    .section-header {
      font-family: $sans-serif;
      line-height: 24px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h4 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #363636;
      }

      .edit {
        margin: 10px 0;
        border: none;
        background: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #363636;
        float: right;
        width: auto;
        padding-right: 0px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &[data-tooltip]::after {
          top: 20px;
          left: 20px;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 6px;
          margin-left: 12px;
        }

        .divider {
          border-top: 1px dashed #85adf9;
          opacity: 1;
          background-color: transparent;
        }
      }
    }

    .tag,
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 0 20px;
      text-transform: capitalize;
    }
  }

  .key-skills-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -20px;

    .section-header-criteria {
      border-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
     

      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }
      h4::first-letter {
        text-transform: uppercase;
      }

    }

    .show-skills {
      display: flex;
      flex-direction: column;
    }

    .section-data {
      margin-bottom: 16px;

      .row {
        display: flex;
        float: right;
        padding: 14px 20px 14px 20px;
        // padding-right: px;
        align-items: center;
        margin-left: 0px;
        border: 1px solid var(--gray-gray-4, #E1E1EE);
        border-top: none;
        // border-left: 1px solid var(--gray-gray-4, #E1E1EE);
        // border-right: 1px solid var(--gray-gray-4, #E1E1EE);
        background: var(--gray-gray-1, #FFF);

        .labels {
          width: 40%;
          text-align: left;
          margin: 0;
          font-size: 16px;
          line-height: 28px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: lowercase
        }
        .labels::first-letter {
          text-transform: uppercase;
        }

        .level {
          width: calc(80% - 120px);
          margin: 0 20px;
        }
      }

      .row:first-of-type{
        border-radius: 12px 12px 0 0;
        border-top: 1px solid var(--gray-gray-4, #E1E1EE);
      }
      .row:last-of-type{
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }

  .experience .section-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .experience-graph-heading {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #363636;
    }

    div {
      float: left;
    }
  }
}


#profile-page #profile-analytics .analytics-dashboard {
  .data-section {
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.08);
    border-radius: 8px;
    padding: 20px;
    min-height: 144px;
    margin-bottom: 28px;

    .section-header {
      font-family: $sans-serif;
      line-height: 24px;
      margin-bottom: 20px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      h4 {
        font-size: 20px;
        line-height: 24px;
      }

      .edit {
        margin: 10px 0;
        border: none;
        background: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #363636;
        float: right;
        width: auto;
        padding-right: 0px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &[data-tooltip]::after {
          top: 20px;
          left: 20px;
        }

        svg {
          width: 24px;
          height: 24px;
          margin-right: 6px;
          margin-left: 12px;
        }

        .divider {
          border-top: 1px dashed #85adf9;
          opacity: 1;
          background-color: transparent;
        }
      }
    }

    .tag,
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding: 0 20px;
      text-transform: capitalize;
    }

    &.other .section-data {
      min-height: 30px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }

    .icon {
      background-color: #e1edff;
      padding: 10px;
      border-radius: 4px;
      width: 44px;
      height: 44px;
      display: inline-block;

      .img-responsive {
        width: 24px;
        height: 24px;
        min-width: 24px;
      }
    }
  }

  // Skills Line Chart
  .skills {
    .chart {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  // Keyskills Column Chart
  .key-skills-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .labels {
      width: 16%;
      text-align: right;
      margin: 0;
      font-size: 16px;
      line-height: 36px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .experience .section-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .experience-graph-heading {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #363636;
    }

    div {
      float: left;
    }
  }
}

#profile-page #profile-analytics #edit-skills {
  margin-top: -20px;

  .section {
    margin: 0 0 20px;
    padding-top: 20px;
    border-top: 1px dashed #85adf9;
  }

  .input-section label,
  .line span {
    @include text16;
  }

  input {
    height: 36px;

    &[type="number"] {
      padding-right: 0;
    }
  }

  .section-header {
    border: 0;
    display: flex;
    align-items: center;

    input,
    .value {
      font-size: 18px;
      line-height: 36px;

      &.col-xs-10 {
        width: calc(83% - 50px);
      }
    }
  }

  .line {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

    button {
      background: transparent;
      border: 0;
      padding: 0;
    }
  }

  .button-section {
    display: flex;

    button {
      @include button(150px, 36px);
    }
  }
}