.analytics .skill-section {
    .level{
        width: calc(80% - 60px);
        margin: 0 20px;
    }
  .key-skills-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .section-data {
      margin-bottom: 16px;
    }
    .section-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 0;
      margin-bottom: 16px;
      align-items: center;
      background-color: #e0ebff;
      padding: 0 16px;
      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .row {
        display: flex;
        float: right;
        padding-right: 20px;
        align-items: center;
    }
    .labels {
      width: 20%;
      text-align: right;
      margin: 0;
      font-size: 16px;
      line-height: 28px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 500;
    }
  }
}
