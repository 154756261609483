#applicant_profile_page {
  background-color: #f4f8ff;
  padding-bottom: 20px;
  padding-top: 88px;

  .list-detail-view>.right-section {
    padding-top: 0;
    &.col-xs-12 {
      width: 100%;
    }
  }

  .info-section {
    height: auto;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;

    .candidate-details {
      h4 {
        font-size: 24px;
        line-height: 36px;
        margin: 0;
      }

      h5 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
      }

      h4,
      h5 {
        font-weight: 600;
      }

      .links {
        margin-bottom: 10px;

        a.email {
          width: 25px;
          display: inline-block;

          img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .stage-name {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #d9d9d9;
          border-radius: 1px;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          margin-right: 13px;

          &:hover {
            box-shadow: 0px 0px 4px rgba(0, 27, 75, 0.06);
            font-size: 15px;
            font-weight: 700;
            cursor: pointer;
          }
        }

        .info-box {
          position: absolute;
          width: 250px;
          height: auto;
          padding: 20px;
          background: white;
          border-radius: 4px;
          border: 2px solid rgba(0, 27, 75, 0.06);
          box-shadow: 0px 0px 4px rgba(0, 27, 75, 0.06);
          top: 20px;
          z-index: 99;

          .close-box {
            float: right;
            cursor: pointer;
          }

          button {
            border: 2px solid $color3;
            background: transparent;
            padding: 8px 12px;
            border-radius: 4px;
            color: $color3;
            font-weight: 500;
          }
        }
      }
    }

    .right-section {
      .control-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 8px;
      }

      .delete-button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 0;
        background: transparent;
        padding: 0;
        margin-right: 70px;
      }

      .interview-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 0;
        background: transparent;
        padding: 0;
        margin-right: 90px;

        img {
          height: 24px;
          width: 24px;
        }

        &::after {
          top: 30px;
          right: 0;
        }
      }
    }
  }

  .data-section {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .button-section {
      margin: 0 0 16px;
      padding-top: 16px;
      background-color: #ffffff;
      @include text16;
      border-bottom: 2px solid #d7e5fd;
      position: sticky;
      top: 0;
      z-index: 2;

      .button {
        padding: 0 12px 2px 0;
        background: none;
        border: 0;
        margin-bottom: -2px;
        border-bottom: 2px solid transparent;
        font-weight: 500;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &:hover {
          border-bottom-color: #a7c6ff;
        }

        &.active {
          border-bottom-color: $color3;
        }
      }
    }

    .section-header h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }

  #notes-section {
    .prev-notes-section {
      max-height: 60vh;
      overflow-y: auto;
      border: 1px solid #f4f8ff;
      padding: 16px;

      .addBtn {
        position: absolute;
        right: 16px;
        top: 20px;
      }

      h3 {
        margin-top: 0;
        font-weight: 600;
        font-size: 20px;
        line-height: 40px;
      }

      .note-item {
        background-color: #f4f8ff;
        padding: 8px;
        margin-top: 16px;

        &.right {
          float: right;
          background-color: #e1edff;
        }

        .header {
          border-bottom: 1px dotted #0b53dd;
        }

        .note {
          font-weight: 500;
        }
      }
    }

    textarea {
      @include input-field(calc(100% - 120px), 40px);
      margin-right: 20px;
      resize: vertical;
    }

    button {
      @include button(100px, 40px);
      color: white;
    }
  }

  // .modal {
  //   .modal-dialog {
  //     min-height: 80vh;
  //     top: 10vh;
  //   }

  //   .modal-content {
  //     overflow-y: auto;
  //     height: 80vh;
  //     align-items: stretch;
  //     padding-top: 40px;
  //   }

  //   h4 {
  //     font-family: "Montserrat";
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 20px;
  //     line-height: 32px;
  //     color: #363636;
  //     border-bottom: 1px solid #e1edff;
  //     padding-bottom: 8px;
  //   }

  //   label {
  //     font-family: "Montserrat";
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 12px;
  //     color: #363636;
  //     z-index: 9999;
  //     margin-top: 20px;

  //     span {
  //       background-color: #ffffff;
  //       padding: 4px;
  //     }
  //   }

  //   .date {
  //     padding-right: 14px;
  //   }

  //   .time {
  //     padding-left: 14px;
  //   }

  //   input {
  //     height: 40px;
  //     background: #ffffff;
  //     border: 1.4px solid #cbddff;
  //     border-radius: 4px;
  //     margin-top: -12px;
  //   }

  //   .button-section {
  //     margin-top: 24px;
  //     margin-bottom: 24px;
  //     border-bottom: 0;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;

  //     button {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       width: 150px;
  //       height: 32px;
  //       background: #ffffff;
  //       border: 1px solid #0b53dd;
  //       border-radius: 4px;

  //       :hover {
  //         border-width: 2px;
  //       }
  //     }

  //     .cancel {
  //       border: none;
  //       background: none;
  //       padding: 0;
  //       width: 70px;
  //       margin-right: 20px;
  //     }

  //     .save {
  //       width: 102px;
  //     }
  //   }

  //   .google-cal {
  //     button {
  //       width: 300px;
  //       height: 45px;
  //     }

  //     h1 {
  //       font-size: 18px;
  //     }

  //     svg {
  //       // padding-top: 10px;
  //       margin-top: 8px;
  //     }
  //   }
  // }

  #interview-section {
    .meeting-detail {
      display: flex;
      // flex-direction: column;
      margin-top: 8px;
      padding-right: 0;
      align-items: center;
      justify-content: space-between;

      .info-heading {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #767676;
        // margin-top: 8px;

        svg {
          margin-right: 8px;
        }

        h5 {
          margin-left: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          // color: #767676;
        }
      }

      .date {
        // margin-top: 8px;
      }
    }

    .info {
      padding-right: 0;
      align-items: center;

      .info-heading {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #767676;
        margin-top: 8px;

        svg {
          margin-right: 8px;
        }
      }

      .list-type {
        list-style-type: disc;
        margin-top: 5px;
      }

      .info-detail {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #363636;
      }
    }

    .section-info {
      padding-bottom: 28px;
      padding-right: 25px;
      padding-top: 20px;
    }

    .organiser-details-section {
      padding-top: 28px;
      padding-bottom: 28px;
      display: flex;
    }

    .organiser-info {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      .organiser-name {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #363636;
      }

      .organiser-position {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #767676;
        margin-top: 4px;
      }
    }

    h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #363636;
    }

    .interview-button-section {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      // margin-top: 20px;
    }

    .join-button {
      @include button(79px, 36px);
      border-radius: 4px;
    }

    .reschedule-button {
      width: 133px;
      height: 36px;
      border: none;
      border-radius: 4px;
      margin-left: 20px;
      background: #c5c5c5;
    }
  }


  

  @import "profile_section";
  @import "candidate_list";
  @import "breadcrumb";
  @import "analytics";
}


#evaluation-section {


  .toggle-section {
    display: flex;
    justify-content: flex-end;
  }
  .toggle-button {
    border: 1px solid #c3c7df;
    border-radius: 4px;
    display: inline-block;
    right: 0;
    position: relative;
    button {
      background-color: transparent;
      border: 0;
      padding: 6px 10px;
      @include text14;
      font-weight: 500;
    }
    button.active {
      background-color: #e4efff;
    }
  }
  .section {
    .section-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .edit {
        width: auto;
      }

      .edit-buttons {
        display: flex;
        flex-direction: row-reverse;

        button {
          background: none;
          border: none;
        }
      }
    }
   

    .section-data {
      display: flex;
      flex-direction: column;

      .graph {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          margin-bottom: 10px;
          height: 20px;
          align-items: center;

          .labels {
            width: 20%;
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .track {
            width: 55% !important;
          }
        }
      }
    }

    .divider {
      border-bottom: 1px dashed #c5c5c5;
      margin: 20px 0;
    }

    .question {
      margin-bottom: 16px;

      * {
        display: block;
        float: left;
        width: 100%;
      }
    }
  }

  #sample-ans-modal {
    .modal-content {
      height: 60vh;

      span {
        @include text16;
        font-weight: 500
      }
    }
  }
}

#status-modal{
  .modal-dialog{
    width: 80vw;
    border-radius: 12px;
  }
  // .modal-content {
  //   border-radius: 12px;
  //   background: var(--gray-gray-1, #FFF);
  //   padding: 16px 18px 18px 18px;
  //   // width: 80%;
  //   z-index: 100000;
  // }

}

#dialer-modal {
 
  .modal-dialog{
    width: 420px;
    
  }
  
  .modal-content {
    border-radius: 12px;
    background: none;
    box-shadow: none;
    border: none;
    z-index: 100000;
  } 
    }
  
    #company-new-modal,
    #company-modal {

      .modal-dialog{
        width: 520px;
      }
      
      .modal-content {
        border-radius: 12px;
        background: var(--gray-gray-1, #FFF);
        padding: 16px 18px 18px 18px;
        // width: 80%;
        z-index: 100000;
      }
    
      .cancel-modal{
        position: absolute;
        right: -10px;
        top: -10px;
        background: none;
        padding: 0;
        border: none;
      }
    
      .heading {
        color: var(--gray-gray-8, #252E4B);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        /* 130% */
      }
    
      .sub-heading {
        color: var(--gray-gray-7, #5F6989);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
      }
    
      input {
        display: flex;
        padding: 11px 10px 11px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    
        height: 40px;
        border-radius: 8px;
        border: 1px solid var(--gray-gray-4, #E1E1EE);
        background: var(--gray-gray-1, #FFF);
      }
    
      .leftInput {
        padding-right: 15px;
      }
    
      .rightInput {
        padding-left: 15px;
      }
    
      label {
        color: var(--gray-gray-8, #252E4B);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
      }
    
      .button-section {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 40px;
    
        button {
          margin-left: 44px;
          border: none;
        }
    
        .save {
          border-radius: 8px;
          background: var(--bright-greek-blue-8, #1369E9);
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    
          display: flex;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
    
          color: var(--gray-gray-1, #FFF);
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 133.333% */
        }
    
        .cancel-button {
          color: var(--gray-gray-8, #252E4B);
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          background: none;
          /* 133.333% */
        }
      }
    
      .success-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        .schedule-heading {
          color: var(--gray-gray-8, #252E4B);
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          margin-top: 15px;
          /* 130% */
        }
    
        .text-heading {
          color: var(--gray-gray-7, #5F6989);
          margin-top: 5px;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
    
        .detail-meeting-sec {
          border-radius: 16px;
          border: 1px solid var(--gray-gray-5, #DADAE4);
          background: var(--gray-gray-1, #FFF);
          padding: 16px;
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
          margin-top: 24px;
    
          .row-data{
            margin-top: 12px;
          }
    
          .candidate-detail{
    
            border-top: 1px solid var(--gray-gray-4, #E1E1EE);
            padding-top: 12px;
            .email-name{
              display: flex;
              flex-direction: row-reverse;
              margin-right: -12px;
              #initialsLogo{
                margin-right: 0px;
              }
    
    
              .email-name-detail{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-right: 11px;
    
                .name{
                  color: var(--gray-gray-8, #252E4B);
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
                .email{
                  overflow: hidden;
                  margin-top: 5px;
                  color: var(--gray-gray-7, #5F6989);
                  text-overflow: ellipsis;
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
          .text-left {
            color: var(--gray-gray-7, #5F6989);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
    
          .text-right {
            color: var(--gray-gray-8, #252E4B);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 128.571% */
          }
    
          .int-heading {
            color: var(--gray-gray-8, #252E4B);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
    
          .row-data {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }