.cand-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  padding: 8px;
  justify-content: space-between;
  &:hover {
    background-color: #f4f8ff;
  }
  &.active {
    background: #e0ebff;
  }
  .status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 8px 0 4px;
    span.stage {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d9d9d9;
      border-radius: 50%;
      margin-right: 8px;
    }
    .text {
      color: green;
      float: right;
      text-align: right;
    }
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    object-fit: contain;
    display: inline-block;
  }
  .position {
    font-weight: 600;
    @include text16;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .company {
    @include text14;
    color: #767676;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
