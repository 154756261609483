/* styles.css */
.ag-theme-custom {
  --ag-foreground-color: rgb(33, 33, 33);
  --ag-background-color: rgb(255, 255, 255);
  --ag-header-foreground-color: rgb(51, 51, 51);
  --ag-header-background-color: rgb(243 245 249);
  --ag-odd-row-background-color: rgb(252, 252, 252);
  --ag-header-column-resize-handle-color: rgb(126, 126, 126);
  --ag-border-color: rgb(224, 224, 224);

  /* Fonts */
  --ag-font-size: 14px;
  --ag-font-family: "Inter", sans-serif;

  /* Spacing */
  --ag-grid-size: 6px;
  --ag-row-height: 48px;
  --ag-header-height: 48px;
  --ag-cell-horizontal-padding: 16px;

  /* Hover States */
  --ag-row-hover-color: #f4f8ff;
  --ag-selected-row-background-color: #f4f8ff;

  /* Column Headers */
  --ag-header-font-weight: 600;
}

.ag-theme-custom .ag-header-cell {
  border-right: 1px solid var(--ag-border-color);
  color: #5f6989;
  font-family: "Inter", sans-serif;
}

.ag-theme-custom .ag-cell {
  border-right: 1px solid var(--ag-border-color);
  color: #16182f;
  font-family: "Inter", sans-serif;
}

.ag-theme-custom .ag-row {
  border-bottom: 1px solid var(--ag-border-color);
}

.ag-theme-custom .ag-row-selected {
  border-color: var(--ag-border-color);
  background-color: #f4f8ff;
}

/* Custom loading overlay */
.ag-theme-custom .ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
}

.ag-theme-custom .ag-overlay-loading-center {
  font-weight: 600;
  color: rgb(71, 71, 71);
}

.ag-theme-custom .ag-filter-apply-panel {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  padding: 8px 12px;
}

/* Style for both Apply and Reset buttons */
.ag-theme-custom .ag-filter-apply-panel button {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Inter", sans-serif;
}

/* Apply button specific styles */
.ag-theme-custom .ag-filter-apply-panel-button {
  background-color: #2563eb;
  color: white;
  border: 1px solid #2563eb;
}

.ag-theme-custom .ag-filter-apply-panel-button:hover {
  background-color: #1d4ed8;
  border-color: #1d4ed8;
}

/* Filter input styling */
.ag-theme-custom .ag-filter-filter {
  border-radius: 4px;
  margin: 8px 0;
}

.ag-theme-custom .ag-filter-wrapper {
  padding: 8px;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
}

/* Filter title styling */
.ag-theme-custom .ag-filter-title {
  color: #374151;
  font-weight: 500;
  padding: 8px 12px;
}

.ag-theme-custom .ag-filter-apply-panel button[ref="clearButton"],
.ag-theme-custom button[class*="ag-filter-clear-button"] {
  background-color: white;
  color: #4b5563;
  border: 1px solid #d1d5db;
  margin-right: 8px;
}

.ag-theme-custom .ag-filter-apply-panel button[ref="clearButton"]:hover,
.ag-theme-custom button[class*="ag-filter-clear-button"]:hover {
  background-color: #f3f4f6;
  border-color: #9ca3af;
}

/* Disabled button state */
.ag-theme-custom .ag-filter-apply-panel button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* .ag-theme-custom .ag-body-horizontal-scroll,
.ag-theme-custom .ag-body-vertical-scroll {
  scrollbar-width: 10px;
  border-radius: 30px;
  scrollbar-color: #e1e1ee transparent;
} */

/* For Webkit browsers (Chrome, Safari, etc.) */
/* .ag-theme-custom .ag-body-horizontal-scroll::-webkit-scrollbar,
.ag-theme-custom .ag-body-vertical-scroll::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.ag-theme-custom .ag-body-horizontal-scroll::-webkit-scrollbar-track,
.ag-theme-custom .ag-body-vertical-scroll::-webkit-scrollbar-track {
  background: transparent;
  width: 0.5em;
  border-radius: 30px;
}

.ag-theme-custom .ag-body-horizontal-scroll::-webkit-scrollbar-thumb,
.ag-theme-custom .ag-body-vertical-scroll::-webkit-scrollbar-thumb {
  height: 0.8em;
  border-radius: 30px;
  background: rgb(243 245 249);
} */
