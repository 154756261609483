#canva {
  font-family: sans-serif;
  font-style: normal;
  top: 68px;
  width: 708px;
  background: #ffffff;
  overflow: scroll;

  img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    object-fit: contain;
    background-color: white;
    display: inline-block;
  }

  .offCanvas-title {
    background: #f4f8ff;
    padding: 28px 0px 28px 0px;
    height: 148px;
  }

  .header-detail {
    height: 100%;
    padding-left: 0px;

    .name {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #363636;
    }

    .position {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #767676;
      margin-top: -5px;
    }
  }

  .message-button {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 12px;
    padding-right: 25px;

    button {
      width: 150px;
      height: 32px;
      background: #f4f8ff;
      border: 1px solid $color3;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $color3;
    }

    button:hover {
      border: 2px solid $color3;
    }
  }

  .offcanvas-body {
    .heading-button-section {
      border-bottom: 2px solid #e1edff;
    }

    .heading-button {
      background: none;
      border: none;
    }

    .heading-button:hover {
      border-bottom: 2px solid #0d4cc5;
    }

    .details-section {
      .details {
        background: #ffffff;
        border: 1px solid #85adf9;
        border-radius: 8px;
        margin-top: 36px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 28px;
      }

      .section-heading {
        border-bottom: 1px solid #85adf9;

        h6 {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #363636;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }

      .info-heading {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #767676;
      }

      .info-detail {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #363636;
        margin-top: 4px;
      }
    }


  }
}

#recruiterProfile {
  margin-top: 20px;

  &::before {
    content: "";
    width: 100vw;
    height: 218px;
    background: #f4f8ff;
    position: absolute;
    left: 0;
    top: 151px;
  }

  .edit-button {
    background: none;
    display: inline-flex;
    padding: 0;
    border: none;
    font-weight: 500;
    font-size: 16px;
    color: #363636;

    span {
      margin-left: 10px;
    }
  }

  .top-section {
    align-items: center;
    height: 218px;
    display: flex;
    background: #f4f8ff;
    padding-left: 0px;

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: #767676;
    }

    .text-section {
      display: flex;
      flex-direction: column;
      margin-left: 40px;

      .button-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
          font-weight: 600;
          font-size: 24px;
          letter-spacing: 0.02em;
          color: #363636;
        }
      }

      .company {
        font-weight: 500;
        font-size: 16px;
        color: #363636;
        margin-top: 16px;
      }

      .description {
        font-weight: 500;
        font-size: 14px;
        color: #636363;
        margin-top: 12px;
      }
    }
  }

  .bottom-section {
    margin-top: 32px;

    .heading-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: #363636;
      }
    }

    .left-section {
      padding-left: 0;

      .contact-info-section {
        background: #ffffff;
        // border: 1px solid #0B53DD;
        border-radius: 4px;
      }

      h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #363636;
      }

      .detail {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;

        .key {
          font-weight: 500;
          font-size: 16px;
          color: #767676;
        }

        .value {
          font-weight: 500;
          font-size: 18px;
          color: #1d61e1;
        }
      }
    }

    .right-section {
      padding-right: 0px;

      .workExperience-section {
        display: flex;
        background: #ffffff;
        border: 1px solid #0b53dd;
        border-radius: 4px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 4px;
          margin-top: 24px;
          margin-right: 20px;
        }
      }

      .empty-state {
        display: flex;
        background: #ffffff;
        border: 1px solid #0b53dd;
        border-radius: 4px;
        min-height: 200px;
      }

      .exp-details {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .designation {
          font-weight: 600;
          font-size: 20px;
          color: #373737;
        }

        .company {
          font-weight: 500;
          font-size: 16px;
          color: #373737;
          margin-top: 4px;
        }

        .button-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 8px;

          .description {
            font-weight: 500;
            font-size: 14px;
            color: #636363;
          }
        }
      }
    }
  }
}

#company-profile {
  padding-right: 0px;
  background-color: #f4f8ff;

  .main-content {

    font-family: "Montserrat";
    font-style: normal;


    .company-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      button {
        display: flex;
        align-items: center;
        width: 111px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #1B5FE1;
        border-radius: 4px;
        justify-content: center;
        margin-right: 20px;

        span {

          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.005em;
          color: #767676;
          margin-left: 10px;
        }
      }

      .menu {
        position: absolute;
        width: 200px;
        height: auto;
        right: 1%;
        left: auto;
        top: 40px;
        text-align: left;
        background: #ffffff;
        box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
        border-radius: 4px;
        border: 0;
        padding: 20px 16px;
        z-index: 1;
        list-style: none;

        li {
          display: flex;
          align-items: center;

          label {
            margin-left: 10px;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #363636;
          }

          input {
            margin-top: -4px;
          }
        }
      }
    }

    h5 {
      font-weight: 600;
      font-size: 20px;
      color: $color5;
    }

    .job-sections {
      padding-right: 20px;
      margin-top: 20px;
    }

    .header {
      height: 60px;
      background: #f4f8ff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .job-details {
      height: 209px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
      border-radius: 8px;
      padding: 10px;
    }

    .image-section {
      margin-top: -40px;
      display: flex;

      .image-text {
        width: 80px;
        height: 80px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 56px;
        color: #0b53dd;
        font-weight: 700;
      }

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
        background: #ffffff;
      }

      h2 {
        margin-left: 12px;
        margin-top: 44px;
        font-weight: 600;
        font-size: 16px;
        color: #363636;
      }
    }

    .desc-text {
      font-weight: 500;
      font-size: 12px;
      color: #363636;
      margin-top: 8px;
      overflow: hidden;
      height: 2.7em;
      text-transform: lowercase;
    }

    .desc-text::first-letter {
      text-transform: capitalize;
    }

    .button-sec {
      display: flex;
      justify-content: space-between;
      margin-top: 29px;
      margin-bottom: 20px;

      button {
        padding: 0;
        background: none;
        border: none;
      }

      span {
        font-weight: 500;
        font-size: 10px;
        color: #767676;
      }
    }

    .add-company-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 209px;

      button {
        background: #f4f8ff;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      button:hover {
        background: #eef2fb;
      }

      span {
        font-weight: 600;
        font-size: 16px;
        color: #363636;
        margin-top: 14px;
      }
    }
  }
}

#company-details .main-content {
  padding-top: 20px;

  &::before {
    content: "";
    width: 100vw;
    height: 158px;
    background: #f4f8ff;
    position: absolute;
    left: 0;
    top: 121px;
  }

  .background-color {
    position: absolute;
    background-color: #f4f8ff;
  }

  .top-section {
    align-items: center;
    height: 158px;
    display: flex;
    background: #f4f8ff;
    padding-left: 0px;

    .image-text {
      width: 80px;
      height: 80px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 56px;
      color: #0b53dd;
      font-weight: 700;
    }

    img {
      width: 80px;
      height: 80px;
      background: #ffffff;
      object-fit: cover;
    }

    .text-section {
      display: flex;
      flex-direction: column;
      margin-left: 32px;

      .name {
        font-weight: 600;
        font-size: 18px;
        color: #363636;
      }

      .company {
        font-weight: 500;
        font-size: 16px;
        color: #363636;
        margin-top: 8px;
        text-transform: lowercase;
      }

      .company::first-letter {
        text-transform: capitalize;
      }

      .description {
        font-weight: 500;
        font-size: 12px;
        color: #767676;
        margin-top: 4px;
      }
    }
  }

  .bottom-section {
    .button-section {
      margin-top: 16px;
      display: flex;

      button {
        display: flex;
        justify-content: center;
        padding: 0;
        background: none;
        border: none;
        padding-bottom: 12px;
        font-weight: 500;
        font-size: 16px;
        color: #363636;
        border-bottom: 1px solid #e1edff;
        min-width: 120px;
      }

      button:hover {
        border-bottom: 2px solid #85adf9;
      }
    }
  }
}

#about-company {
  .key-points-section {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .key-points {
      display: flex;
      flex-direction: column;
    }
  }

  .description {
    margin-top: 32px;
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
    color: #363636;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #767676;
  }
}

#company-job-list {
  .job-list {
    display: flex;
    flex-direction: row;
    height: 128px;
    padding-left: 15px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
    border-radius: 8px;
    align-items: center;
    margin-top: 16px;

    .data-item {
      &:nth-of-type(1) {
        width: 25%;
        text-transform: capitalize;
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        width: 18.75%;
        text-transform: capitalize;
      }
    }

    .images {
      display: flex;

      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: contain;
        background-color: white;
        display: inline-block;
        border: 1px solid #2465e3;
      }

      span {
        border-radius: 50%;
        background: #2465e3;
        width: 40px;
        height: 40px;
        border: 1px solid #2465e3;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }

      .imageCount {
        margin-left: -35px;
      }
    }

    .imageLogo {
      display: flex;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      margin-right: 20px;
      object-fit: contain;
      background-color: #294568;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 80px;
      line-height: 24px;
    }

    img {
      border-radius: 50%;
      // border: $border;
      width: 80px;
      height: 80px;
      margin-right: 20px;
      object-fit: contain;
      background-color: white;
      display: inline-block;
    }

    span {
      height: 32px;
      background: #e1edff;
      border-radius: 4px;
      align-items: center;
      padding: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.005em;
    }

    .date {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .position-no {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .position {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #363636;
      text-transform: lowercase;
    }

    .position::first-letter {
      text-transform: capitalize;
    }

    .company {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #767676;
      margin-top: -8px;
    }
  }
}

.truncate-hover {
  max-width: 200px; /* Set the maximum width */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add an ellipsis (...) when text overflows */
  cursor: pointer; /* Change cursor on hover */
  transition: max-width 0.3s; /* Add a smooth transition effect */

  /* Show full text on hover */
  &:hover {
    max-width: none;
    transition: max-width 0.3s;
  }
}
