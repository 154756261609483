#saved-jobs, #applied-jobs {
  overflow: auto;

  .headingText {
    font-weight: 600;
    font-size: 20px;
    margin-left: 15px;
    letter-spacing: 0.02em;
    color: #363636;
  }

  .list-length {
    font-family: $sans-serif;
    color: #426bba;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    position: fixed;
  }

  .job-post {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
    border-radius: 4px;
    height: 108px;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    align-items: center;
    a {
      color: #363636;
    }
    .position {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .logo-section {
        width: 68px;
        height: 68px;
        border-radius: 8px;
        margin-right: 12px;
        img {
          width: inherit;
          height: inherit;
          border-radius: inherit;
        }

        .laterLogo{
          background: rgb(193, 154, 107);
          width: 68px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: inherit;
          span{
            color: #ffffff;
            font-size: 28px;
            font-weight: 500;
          }
        }
      }
      .text-section {
        width: calc(100% - 80px);
      }
    }
    .location{
      *{
        display: inline-block;
      }
    }
    .button-group {
      display: flex;
      justify-content: space-between;
      .button-section {
        align-self: flex-end;
        // width: 156px;

        .apply {
          // @include button(156px, 44px);
          background: none;
          border: none;

          span{
            color: #0b53dd;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.4;
          }
        }

        .apply[disabled] {
          background: linear-gradient(
            113.18deg,
            #82f9df 0.46%,
            #5ef7ef 60.78%,
            #1be3d2 99.3%
          );

          .label {
            color: $black;
          }
        }
      }
    }
   
    .section-2 h5 {
      color: #767676;
      svg {
        margin-right: 5px;
      }
    }
    .message-button {
      background: none;
      border: none;
      float: right;
    }
    .apply span,
    .status-text {
      @include text16;
    }
    h4 {
      font-weight: 600;
      @include text16;
      margin: 0;
    }
    h5,
    .company_name {
      @include text14;
    }
  }
}
