#admin {
  .main-content {
    .heading-section {
      display: flex;
      flex-direction: row;
      border-bottom: 2px solid #f4f8ff;
      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #363636;
      }
    }

    .image-section {
      padding-left: 15px;
    }

    .list-recruiter {
      height: 96px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
      border-radius: 4px;

      .list-section {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .button-section {
        justify-content: space-between;
      }

      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #363636;
      }

      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #363636;
      }

      img {
        width: 48px;
        height: 48px;
        background: #ec7303;
        border-radius: 50%;
        margin-right: 20px;
      }

      .reject-button {
        border: none;
        background: none;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        text-decoration-line: underline;
        color: #363636;
      }

      .accept-button {
        width: 90px;
        height: 36px;
        margin-right: 15px;
        background: #ffffff;
        border: 1.4px solid #0b53dd;
        border-radius: 4px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #0b53dd;
      }
    }
  }
}

#navbar {
  position: fixed;
  background: #85adf9;
  height: 66px;
  display: flex;
  align-items: center;

  ul {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    color: #ffffff;
    font-size: 20px;
  }
}

#adminDashboard {
  .carousel {
    button,
    .control-arrow {
      opacity: 0.75;
      &.control-next:before {
        border-left-color: darkgray;
      }
      &.control-prev:before {
        border-right-color: darkgray;
      }
    }
    .control-dots li {
      background: #0004;
    }
  }
  .preloader__text {
    display: none !important;
  }
}
