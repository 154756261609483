/*=*=*=*=*=*=*=*=*=*=*=*=* HEADER *=*=*=*=*=*=*=*=*=*=*=*/
.nav-bar {
  height: $navbar-height;
  display: flex;
  align-items: center;
  background-color: white;
  top: 0;
  left: 0;

  // For Recruiter
  &.recruiter {
    .middle {
      width: calc(100% - 160px - 336px);

      .recruiterSearchBar {
        display: block;
      }

      .searchBar {
        display: none;
      }

      form {
        max-width: 500px;
        // box-shadow: none;

        .job-candidate-search {
          height: 40px;
          width: calc(100% - 56px);
          background: #ffffff;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          // border: 1px solid rgba(0, 87, 255, 0.45);
          border-right: 0;
        }
      }
    }

    .right {
      width: 336px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button-job-post {
        display: block;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }

        .symbal {
          font-size: 20px;
          margin-right: 8px;
        }
      }
    }
  }

  .middle,
  .right {
    &[hidden] {
      display: none !important;
    }
  }

  /**********  LOGO   ***********/
  .left {
    width: 160px;

    .logo {
      position: relative;
      text-decoration: none !important;

      &:hover,
      &:active {
        text-decoration: none !important;
      }
    }

    span {
      display: none;
    }
  }

  /**********  SEARCH BAR   ***********/
  .middle {
    width: calc(100% - 160px - 155px);

    .recruiterSearchBar {
      display: none;
    }

    form {
      max-width: 700px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      box-shadow: 0 0 0px 1px rgba(0, 87, 255, 0.45);
      border-radius: 8px;
      display: flex;

      .auto-complete-input {
        width: 100%;
        border: none;
        height: 100%;
        box-shadow: none;
      }

      [placeholder] {
        font-size: 16px;
        line-height: 24px;
      }

      .auto-complete-list {
        position: absolute;
        z-index: 100;
        width: 100%;
        max-width: 500px;
        max-height: 400px;
        overflow-y: auto;
        top: 42px;
        border-radius: 4px;
        background: white;
        box-shadow: 0 8px 8px 0 #00000052;
        padding: 20px;

        li {
          list-style: none;
          font-size: 16px;
          line-height: 24px;
          padding: 5px 0;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
          }
          .searchType {
            font-size: 10px;
            color: green;
          }
        }

        li:hover {
          background: #f4f8ff;
        }
      }

      #search_location {
        width: 30%;

        input {
          height: 40px;
          width: 100%;
          padding-right: 25px;
          background-color: #e1edff;
          border: 0;
          border-radius: 8px 0px 0px 8px;
        }
      }

      #search_position {
        width: calc(70% - 56px);

        input {
          width: 100%;
          height: 40px;
          background: #ffffff;
          border-radius: 0;
          border: 0;
        }
      }

      .searchButton {
        @include button(56px, 40px, $fill-color: #ffffff);
        background: #ffffff;
        border: 0;
        background-image: none;
        border-radius: 0 8px 8px 0;
        svg {
          width: 25px;
          height: 25px;
        }
        &:hover {
          @include button(56px, 40px, $fill-color: #fff0);
          border: 0;
          path {
            stroke: white;
          }
        }
      }
    }
  }

  .right {
    width: 154px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      cursor: pointer;
    }

    .menu-icon {
      margin-left: 30px;
      display: inline-block;

      .menu {
        margin-left: 44px;
      }

      &.profile {
        display: flex;
        align-items: center;

        .imageLogo {
          width: 40px;
          height: 40px;
          object-fit: contain;
          margin-right: 6px;
          // display: flex;
          display: inline-block;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;

          span {
            display: flex;
            color: #ffffff;
            font-weight: 40px;
            font-size: 15px;
            line-height: 24px;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            font-family: "Montserrat", sans-serif;
            font-style: normal;
          }
        }

        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          margin-right: 6px;
          object-fit: cover;
          display: inline-block;
          float: inherit;
          cursor: pointer;
        }
      }
    }

    button {
      @include button(182px, 44px);
      color: #ffffff;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.005em;
      border-radius: 4px;
      border: 1.4px solid #0b53dd;

      .elipse {
        font-weight: 700;
        font-size: 30px;
        width: 24px;
        height: 24px;
        background: #ffffff;
        border-radius: 50%;
        color: $color3;
        margin-right: 10px;
      }
    }

    button:hover {
      @include button(182px, 44px);
    }

    .button-job-post {
      display: none;
    }
  }

  /**********     DROPDOWN MENU      *********/
  .menu {
    position: absolute;
    width: 312px;
    height: auto;
    right: 2%;
    left: auto;
    top: 40px;
    text-align: left;
    background: #ffffff;
    box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
    border-radius: 4px;
    border: 0;
    padding: 20px 16px;

    li,
    .line {
      list-style: none;
      width: 100%;
      float: left;
    }

    img {
      width: 44px;
      height: 44px;
    }

    .imageLogo {
      width: 44px;
      height: 44px;
      object-fit: contain;
      background-color: #4f2968;
      margin-right: 6px;
      display: inline-block;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      span {
        display: flex;
        color: #ffffff;
        font-weight: 40px;
        font-size: 15px;
        line-height: 24px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
      }
    }

    .profile {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h4 {
        margin-bottom: 0;
      }
    }

    .goto-profile {
      display: flex;
      align-items: center;

      h5 {
        width: calc(100% - 24px);
      }
    }

    .progress_bar {
      margin-top: 20px;

      .bar {
        width: 100%;
        height: 0px;
        border: 4px solid #e1edff;
        border-radius: 4px;
      }

      .status {
        width: 70%;
        position: absolute;
        top: 0;
        border: 4px solid $color3;
        border-radius: 4px;
      }
    }

    h2 {
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #363636;
      margin: 0;
    }

    h4 {
      font-family: $sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    h5 {
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #363636;
      margin: 0;
      padding: 10px 0;
    }

    .line {
      border: 1px solid #e1edff;
      margin: 8px 0;
    }

    li {
      &:hover:not(:first-of-type) {
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 40px;
          background-color: $color3;
          left: 2px;
        }
      }

      a {
        text-decoration: none;
        color: #363636 !important;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }
}

/*=*=*=*=*=*=*=*=*=*=*=*=* HEADER END *=*=*=*=*=*=*=*=*=*=*=*/
