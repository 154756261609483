#candidates {
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-top: 10px;
    }
  }

  .heading-row {
    padding-left: 18px;
    padding-right: 18px;

    h5 {
      &:nth-of-type(1) {
        width: 35%;
      }

      &:nth-of-type(2) {
        width: 25%;
      }

      &:nth-of-type(3) {
        width: 40%;
      }
    }
  }

  .data-row {
    padding: 20px 22px 20px 18px;

    .data-item {
      &:nth-of-type(1) {
        width: 35%;
      }

      &:nth-of-type(2) {
        width: 25%;
      }

      &:nth-of-type(3) {
        width: 40%;
      }

      // &:nth-of-type(4),
      // &:nth-of-type(5) {
      //   width: 15%;
      // }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      text-transform: capitalize;
    }

    h4 {
      margin: 0;
      margin-bottom: 4px;

      color: var(--gray-gray-8, #252e4b);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;

      svg {
        margin-left: 8px;
      }
    }

    h6 {
      margin: 0;
      color: var(--gray-gray-7, #5f6989);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .social-logo {
      display: flex;
      align-items: center;
      margin-top: 10px;

      span,
      a {
        display: flex;
        width: 26px;
        height: 26px;
        cursor: pointer;
        // padding: 4px 10px;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        border-radius: 8px;
        border: 1px solid var(--gray-gray-4, #e1e1ee);
        background: var(--gray-gray-1, #fff);
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.04);
      }

      .tooltip-message {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        opacity: 0.9;
        transition: visibility 0.2s ease-in-out;
      }
    }

    img {
      border-radius: 50%;
      width: 64px;
      height: 64px;
      margin-right: 20px;
      object-fit: cover;
      background-color: white;
      display: inline-block;
    }

    .candidate-position {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-top: -3px;
      color: #363636;
    }

    .candidate-status {
      justify-content: center;
      display: inline-grid;

      h5 {
        font-weight: 500;
        font-size: 14px;
        color: #363636;
      }
    }

    .status {
      display: flex;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        background: #d9d9d9;
        border-radius: 1px;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #ffffff;
        margin-right: 13px;
      }
    }

    .match {
      .chart-section {
        display: flex;
        align-items: center;

        span {
          margin-left: 6px;
          color: var(--gray-gray-8, #252e4b);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .match-detail {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        span {
          margin-right: 12px;
          color: var(--gray-gray-8, #252e4b);
          display: flex;
          text-align: center;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-top: 7px;

          svg {
            margin-right: 3px;
          }
        }
      }

      // display: flex;
      // align-items: center;
      // justify-content: flex-start;

      // span {
      //   margin-left: 10px;
      // }
    }

    .schedule-button-section {
      display: flex;
      justify-content: flex-end;
      button {
        border-radius: 8px;
        border: 1px solid #dadae4;
        background: #fff;
        display: flex;
        height: 32px;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin-left: 12px;
        &:not(:last-of-type):hover {
          box-shadow: 0 0 4px #dadae4;
          svg path {
            stroke-width: 1.4px;
          }
        }
        &:last-of-type:hover svg path {
          stroke: #1369e9;
          stroke-width: 2px;
        }
      }
    }

    .date {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .position-no {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .position {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .company {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #767676;
    }
  }
  #match-chart {
    text.label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #252e4b;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
    }
    .legend {
      padding: 4px 16px;
      background-color: white;
      border: 1px solid #d4d4d4;
      border-radius: 8px;
      margin: 8px 0 0;
      width: auto;
      float: right;
      gap: 27px;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      align-content: flex-start;
      div .icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid white;
        outline: 1px solid;
        margin-right: 8px;
        text-align: left;
      }
    }
  }
}

#linkedin-candidates {
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-top: 10px;
    }
  }

  .heading-row h5 {
    &:nth-of-type(1) {
      width: 31%;
    }

    &:nth-of-type(2) {
      padding-left: 16px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      width: 19%;
    }

    &:nth-of-type(4),
    &:nth-of-type(5) {
      width: 15%;
    }
  }

  .data-row {
    .data-item {
      &:nth-of-type(1) {
        width: 31%;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 19%;
      }

      &:nth-of-type(4),
      &:nth-of-type(5) {
        width: 15%;
      }
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      text-transform: capitalize;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    h6 {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
    }

    img {
      border-radius: 50%;
      width: 64px;
      height: 64px;
      margin-right: 20px;
      object-fit: cover;
      background-color: white;
      display: inline-block;
    }

    .candidate-position {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-top: -3px;
      color: #363636;
    }

    .candidate-status {
      justify-content: center;
      display: inline-grid;

      h5 {
        font-weight: 500;
        font-size: 14px;
        color: #363636;
      }
    }

    .status {
      display: flex;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        background: #d9d9d9;
        border-radius: 1px;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #ffffff;
        margin-right: 13px;
      }
    }

    .match {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        margin-left: 10px;
      }
    }

    .date {
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .position-no {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .position {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #363636;
    }

    .company {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #767676;
    }
  }
}

#Question-modal {
  .modal-content {
    background: #eff0f2;
    border-radius: 12px;
    padding: 16px 18px 18px 18px;

    .modal-close-button {
      display: flex;
      justify-content: space-between;

      button {
        border: none;
        padding: 0;
      }
    }

    h4 {
      color: #252e4b;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }

    .question {
      padding: 18px 18px;
      border-radius: 12px;
      border-bottom: 1px solid var(--gray-gray-3, #eff0f2);
      background: #fff;
    }

    .question-answer-section {
      border-radius: 12px;
      background: #fff;
      // padding: 18px;
      margin-top: 22px;

      .question-number {
        color: var(--gray-gray-8, #252e4b);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        /* 137.5% */
      }

      .question-text {
        color: var(--gray-gray-8, #252e4b);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        /* 143.75% */
      }

      .sample-answer-tag {
        color: var(--gray-gray-7, #5f6989);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 18px;
      }

      .text-section {
        border-radius: 11.25px;
        margin-top: 12px;
        background: var(--gray-gray-2, #f9f9f9);
        // padding: 16px;

        span {
          color: rgba(37, 46, 75, 0.85);
          font-size: 14px;
          // font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }

        .answer-text {
          padding: 12px 16px 12px 16px;
        }

        .header-sample-ans {
          border-radius: 8px 8px 0px 0px;
          background: var(--moray-eel-green-1, rgba(0, 172, 133, 0.1));
          padding: 8px 18px 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .header-tag {
            display: flex;
            color: #252e4b;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            svg {
              margin-right: 6px;
            }
          }
        }
      }
    }

    .questions-tag {
      display: flex;
      flex-wrap: wrap;

      .difficulty-tag {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 5px;
        padding-left: 0;
        margin-right: 30px;

        .color {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .subject-tag {
        margin-top: 10px;
        border: 1px solid var(--gray-gray-3, #eff0f2);
        background: #fff;
        display: flex;
        padding: 5px;
        border-radius: 7px;
        margin-right: 15px;
      }
    }
  }
  .right-side-icon {
    span:hover {
      svg {
        path {
          stroke: teal;
          stroke-width: 3px;
        }
      }
    }
  }
}
