.no-candidates {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    margin-bottom: 36px;
  }
  .import-button {
    background: transparent;
    border: 2px solid $color3;
    padding: 10px 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .symbol {
      margin-right: 10px;
    }
    .text {
      color: $color3;
      margin: 0;
      font-size: 18px;
      font-weight: 500;
    line-height: 1.1;
    }
    &:hover {
      box-shadow: 0 0 4px 0px rgba(83, 65, 248, 0.555);;
    }
  }
}
