.hiringPipeline {
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 28px;

  &::-webkit-scrollbar {
    height: 2px;
  }

  .stage {
    min-width: 200px;
    width: 20%;
    background-color: #f4f8ff;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;

    &:nth-of-type(1),
    &:nth-of-type(5) {
      .heading-candidates {
        box-shadow: inset 0 5px 0 0 #9bbdff;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(6) {
      .heading-candidates {
        box-shadow: inset 0 5px 0 0 #fdff87;
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(7) {
      .heading-candidates {
        box-shadow: inset 0 5px 0 0 #fea4a4;
      }
    }

    &:nth-of-type(4),
    &:nth-of-type(8) {
      .heading-candidates {
        box-shadow: inset 0 5px 0 0 #93ffb1;
      }
    }
  }

  .candidates-list {
    height: 56vh;
    padding: 5px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  #Hired,
  #Rejected {
    .data-item:hover {
      background-color: white;
    }
  }

  .data-item {
    min-height: 92px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
    border-radius: 4px;
    margin: 0 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    white-space: initial;
    padding: 0 16px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }



    .data-sec {
      display: flex;

      .text {
        margin-left: 10px;

        h4 {
          font-weight: 600;
          font-size: 1.724vh;
          line-height: 1.5;
          letter-spacing: 0.02em;

        }

        h5 {
          font-size: 1.508vh;
          line-height: 1.57;
          letter-spacing: 0.02em;
          color: #767676;
        }
      }
    }

    .match-img-bg {
      width: 46px;
      height: 46px;
      padding: 3px;
      border-radius: 50%;
      margin: 10px 0;
    }

    img {
      width: 40px;
      height: 40px;
      padding: 2px;
      border-radius: 50%;
      object-fit: cover;
      background-color: white;
      display: inline-block;
    }
  }

  .heading-candidates {
    background: #ffffff;
    border: 1px solid #85adf9;
    border-radius: 4px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #363636;
  }
}