#profile-section {
  .details {
    padding-right: 48px;
  }


  .edit-button {
    background: none;
    border: none;
    position: fixed;
    top: 148px;
    right: 36px;
    z-index: 101;

    // svg{
    //   width: 24px;
    //   height: 24px;
    // }
  }

  .done-button {
    right: 36px;
  }

  .revert-button {
    right: 70px;
  }

  .edit-candidate {
    input {
      height: 36px;
    }

    textarea {
      height: 200px;
      margin-bottom: 20px;
    }

    label {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #767676;
      margin-top: 20px;
    }

    h5 {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #363636;

    }
  }



  .section {
    .section-data {
      font-size: 16px;
      line-height: 24px;
      color: #767676;
      margin-top: 8px;
    }

    &.resume-section a {
      display: inline-flex;
      align-items: center;

      img.icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    &:not(:first-of-type, :last-of-type) {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #d0dffb;
    }

    .data-row{
      // border-top: 1px solid #d0dffb;
      box-shadow:0px 0px 8px rgba(0, 27, 75, 0.04);
    }
    // Common font CSS
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #454545;
      margin-top: 4px;
    }

    .companyName {
      font-weight: 500;
      font-size: 16px;
      color: #454545;
      margin-top: 4px;
    }

    .duration {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #767676;
      margin-top: 12px;
    }

    .grade,
    .sub-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #767676;
    }

    .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #767676;
      margin-top: 12px;
    }
  }

  .education-section,
  .experience-section {
    .data-row {
      padding: 8px 0px;
      display: flex;
      align-items: center;
    }

    .date-row,
    .edit-row {
      display: flex;
      justify-content: space-between;
    }

    .date-row {
      align-items: flex-start;
    }

    .edit-row {
      align-items: flex-end;
    }
  }
}