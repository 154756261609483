// Default modal styling
.modal.fade.in {
  .modal-dialog {
    max-width: 60vw;
    min-height: 70vh;
    top: 15vh;
    margin: auto;

    .modal-content {
      max-height: 100%;
      padding: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      box-shadow: 0 12px 24px rgb(0 0 0 / 8%);
    }

    .cancel {
      position: absolute;
      background: none;
      border: 0;
      right: 0;
      padding: 0;
      margin-right: -8px;
      margin-top: -8px;
      z-index: 80;
    }

    .button {
      margin-top: 40px;
      float: right;
      margin-left: 10px;
      @include button(130px, 40px);
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.01em;

      color: #ffffff;

      &.reset {
        background: #c5c5c5;
        color: #363636;
      }
    }
  }
}
// Empty list/no data styling
.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    background: none;
    border: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    color: #0b53dd;
  }

  h2 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: #373737;
    margin-left: 20px;
  }
}

#confirmRecruiter {
  .cancel {
    margin-top: -20px;
    margin-right: 0px;
  }

  .main-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 30px;

    h3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #363636;
    }

    img {
      width: 260px;
      height: 236px;
      margin-top: 36px;
    }

    span {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #767676;
    }

    .text {
      margin-top: 36px;
    }
  }

  .modal.fade.in .modal-dialog .cancel {
    display: none;
  }
}
@import "components/loader";
@import "components/scrollbar";
@import "components/toggle_switch";
