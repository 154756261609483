.auth-pages {
  &#login {
    padding: 0;

    .main-content {
      flex-direction: row;

      input{
        border: 1px solid var(--gray-gray-5, #DADAE4);
        background: var(--gray-gray-1, #FFF);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      }

      .left-section,
      .sub-container {
        max-height: 100%;
        height: 100%;
        width: 50%;
        border-radius: 0;
      }

      .landing-backdrop {
        background: #F3F5F9;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 5%;

        img {
          width: 75%;
        }

        h5 {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0.02em;
          text-align: center;
          color: #2d3542;
        }
      }

      .sub-container>* {
        max-width: 560px;
      }
    }
  }
}

.auth-pages {
  &#persional-recruiter {
    padding: 0;

    .main-content {
      flex-direction: row;

      .email {
        padding-left: 20px;
      }

      input {
        height: 6.89vh;
        width: 100%;
        background: #FFFFFF;
        border: 1.4px solid #c8c8c8;
        ;
        border-radius: 8px;
        padding-left: 132px;
      }


      .phoneInputCountry {
        height: 6.89vh;
        width: 50px;
      }

      .PhoneInputInput {
        height: 6.89vh;
      }

      .PhoneInputCountry {
        position: absolute;
        width: 112px;
        padding-left: 20px;
        height: 6.89vh;
        background: #F4F8FF;
        border-top: 1.4px solid #c8c8c8;
        ;
        border-left: 1.4px solid #c8c8c8;
        ;
        border-bottom: 1.4px solid #c8c8c8;
        ;
        border-radius: 7px 0px 0px 7px;
      }

      .PhoneInputCountrySelectArrow {
        width: 15px;
        height: 15px;
        margin-left: 35px;
        border-right: 3px solid #363636;
        border-bottom: 3px solid #363636;
      }

      .PhoneInputCountryIconImg {
        background: none;
        margin-top: -5px;
        width: 33px;
        height: 24px;
      }

      .PhoneInputCountryIcon--border {
        background-color: none;
        box-shadow: none;
      }

      .headingText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #363636;

      }

      .phoneInput {
        height: 6.89vh;
      }


      .left-section,
      .sub-container {
        max-height: 100%;
        height: 100%;
        width: 50%;
        border-radius: 0;
      }

      .landing-backdrop {
        background: #f4f8ff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 5%;

        img {
          width: 75%;
        }

        h5 {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0.02em;
          text-align: center;
          color: #2d3542;
        }
      }

      .sub-container>* {
        max-width: 560px;
      }
    }
  }
}