#description {
  .show-skill {
    display: flex;
    float: right;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    h4 {
      margin: 0 10px;
    }
  }

  .other {
    margin-top: 16px;
    margin-bottom: 24px;
    padding-left: 30px;
    font-weight: 500;

    border-radius: 12px;
    background: #FFF;
    padding: 22px 32px 22px 24px;
    box-shadow: 0px 2px 4px 0px rgba(62, 72, 84, 0.10);
    border: 1px solid var(--gray-gray-4, #E1E1EE);

    .section-data {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: 0 28px 0 28px;
    }

    .label-text {
      margin-top: 0;
      text-transform: capitalize;
      margin-left: 8px;
      max-width: calc(100% - 32px);

      p {
        margin: 0;
      }
    }
  }

  #descriptionTxt {
    padding-right: 70px;

    .section:not(:first-of-type) {
      margin-top: 32px;
    }

    .heading-title {
      height: auto;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #363636;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    label {
      padding-left: 28px;
      padding-right: 28px;
      border-radius: 4px;
      padding-top: 4px;
      height: 36px;
      margin-right: 10px;
      margin-top: 8px;
    }

    .heading-description {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #363636;
    }

    .label-text,
    .description-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #767676;


      h1,h2,h3{
        font-size: 16px;
        line-height: 24px;
        color: black;
        margin-top: 8px;
      }

      * {
        font-size: 16px;
        line-height: 24px;
        color: #767676;
        margin-top: 8px;
      }
    }

    .skills-heading {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #363636;
      margin-top: 12px;
    }

    .skills-text {
      font-weight: 500;
      border: none;
      height: 28px;
      background: #f4f8ff;
      border-radius: 2px;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.005em;
      color: #363636;
      text-transform: capitalize;
    }
  }
}