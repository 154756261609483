.toggle-switch {
  display: inline-block;
  width: 40px;
  height: 20px;
  position: relative;
  .toggle-bg {
    width: 40px;
    height: 20px;
    float: left;
    background: $color7;
    border: 1px solid white;
    border-radius: 10px;
  }
  .toggle-button {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid $color5;
    position: absolute;
    top: -1px;
    &.left {
      left: 0;
    }
    &.right {
      right: 0px;
    }
  }
}
