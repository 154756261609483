
// Full page loader
.loader {
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex !important;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
  }
  