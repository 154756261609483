.actions {
  width: 120px;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  & > div {
    display: block;
    float: right;
    position: relative;
    width: 24px;
    height: 24px;
    svg {
      height: 24px;
      width: auto;
    }
  }
  .menu-button-section {
    button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
    }
    .menu-options {
      border: 0;
      padding: 8px 0;
      z-index: 101;
      list-style-type: none;
      position: absolute;
      background: #ffffff;
      box-shadow: 2px 2px 12px rgba(0, 27, 75, 0.12);
      border-radius: 4px;
      right: 0;
      width: 120px;
      li {
        padding: 4px 10px;
        margin-top: 4px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #363636;
        cursor: pointer;
        &:hover {
          background-color: #f4f8ff;
        }
      }
      label {
        padding: 4px 10px;
        margin-top: 4px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #363636;
        cursor: pointer;
        &:hover {
          background-color: #f4f8ff;
        }
      }
    }
  }

  label.import {
    width: 24px;
    height: 24px;
    &[data-tooltip]::after {
      top: 28px;
      right: 20px;
      font-weight: 500;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  #upload-resume {
    label {
      width: 100%;
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #767676;
    }

    p {
      text-align: center;
      margin-bottom: 0;
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #767676;
    }

    .upload-section {
      display: flex;
      border: 1px solid $color3;
      border-radius: 8px;
      height: 124px;
      padding: 10px 0;
      flex-direction: column;
      justify-content: space-evenly;
    }

    input[type="text"],
    input[type="email"] {
      @include input-field(100%, 44px);
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      font-family: $sans-serif;
      letter-spacing: 0.02em;
      color: $color5;
    }

    input[type="file"] {
      display: none;
      @include button(
        auto,
        44px,
        $text-size: 16px,
        $text-height: 26px,
        $text-spacing: 0.01em,
        $text-color: transparent
      );
      margin: 0 auto;
      padding: 0;
      min-width: 226px;
      max-width: 90%;

      &::before {
        content: attr(title);
        background: white;
        color: #0b53dd;
        height: 40px;
        width: 100%;
        position: relative;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px double transparent;
        padding: 7px;
      }

      &:hover,
      &:active {
        border-color: #0b53dd;
        background: white;

        &::before {
          outline: 0;
          box-shadow: 1px 2px 6px rgba(39, 69, 122, 0.08);
        }
      }
    }
  }
  .done-button,
  .revert-button,
  .edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    position: relative;
    border: 0;
    border-radius: 4px;
    padding: 0;
    width: 24px;
    height: 24px;
    margin-left: 16px;
    svg {
      margin: 0;
      width: 24px;
      height: 24px;
    }
    &[data-tooltip]::after {
      top: 28px;
      right: 20px;
    }
  }
  .done-button,
  .revert-button {
    padding: 2px;
  }
  .submitted {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
