@tailwind components;

@layer components {
  .btn {
    @apply rounded-lg text-center justify-center items-center inline-flex disabled:opacity-60 disabled:cursor-default disabled:pointer-events-none;
  }
  .btn-lg {
    @apply btn h-12 px-[1.125rem] py-3 text-md gap-3;
  }
  .btn-md {
    @apply btn h-10 px-4 py-2 text-base gap-2;
  }
  .btn-sm {
    @apply btn h-6 px-3.5 py-1 text-sm gap-1;
  }
  .btn-primary {
    @apply btn bg-blue-800 border border-blue-800 text-white hover:bg-blue-900 hover:border-blue-900;
  }
  .btn-sec {
    @apply btn bg-white border border-gray-500 text-gray-800 hover:border-blue-900 hover:text-blue-900;
  }
}
