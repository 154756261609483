#codingSection {
  height: calc(100vh - 114px);

  .mtk8,
  .view-line span {
    font-family: Consolas, "Courier New", monospace !important;
  }

  .top-section {
    height: 10%;
  }

  .left-section {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(62, 72, 84, 0.1);
    border-radius: 12px;
    height: 100%;
    padding: 24px;

    .question-sec {
      height: calc(100vh - 204px);
      overflow: scroll;
    }
    .next-button-sec {
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.01em;
      color: #252e4b;
    }

    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 122.2%;
      letter-spacing: 0.02em;
      color: #5f6989;
      margin: 0;
    }

    .section-data {
      padding: 12px 0;
      // font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #5f6989;
    }
  }

  .right-section {
    padding-left: 16px;
    height: 100%;

    .editor-section {
      border-radius: 12px;
      padding: 16px 18px;
      height: 60%;
      background: #1e1e1e;

      select {
        padding: 5px 4px 5px 8px;
        width: 106px;
        height: 30px;
        background-color: #eff0f2;
        border-radius: 4px;
        margin-bottom: 10px;
      }

      & > section {
        height: calc(100% - 66px) !important;
      }
    }

    .output-section {
      height: 38%;
      margin-top: 2%;
      background: #f9f9f9;
      border-radius: 12px;
      overflow: hidden;

      .loader-section {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .right-code-output,
      .left-code-output {
        overflow: auto;
      }
      .right-code-output {
        background: #ffffff;
        height: 100%;
        .data-section {
          margin-bottom: 20px;
          .heading-section {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #252e4b;
          }
          .response-section {
            padding: 6px 10px 6px 12px;
            background: rgba(239, 240, 242, 0.5);
            border-radius: 8px;
            min-height: 33px;
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            color: #252e4b;
          }
        }
      }
      .left-code-output {
        padding: 8px;

        .list-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          height: 48px;
          border-radius: 4px;
          border: 0;
          background: transparent;
          width: 100%;
          &:first-of-type{
            background-color: #dadae4;
          }
          svg {
            margin: 13px;
          }
          h4 {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #252e4b;
          }
        }
      }
    }

    .button-section {
      display: flex;
      justify-content: flex-end;
      height: 26px;
      margin: 0;

      button {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        padding: 4.5px 8px;
        height: 26px;
        background: #eff0f2;
        border: 0;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 122.2%;
        letter-spacing: 0.01em;
        color: #252e4b;
        margin: 0 8px;

        &:hover {
          background: #dadae4;
        }
      }
    }
  }
}

#output-window {
  span {
  }
}
