#assessment {
  background-color: #f3f5f9;
  padding: 0 2.5% 24px;
  max-height: 100vh;

  .main-content {
    a {
      // font-family: 'Inter';
      position: absolute;
      right: 0;
      height: 40px;
      bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #5f6989;

      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .header-section {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 20px;

      .jobPosition-section {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;

        button {
          width: 40px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e1e1ee;
          border-radius: 10px;
        }

        .text {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
          color: #252e4b;
        }
      }

      .stopSharing-button {
        width: 162px;
        height: 40px;
        background: #1369e9;
        border: 1px solid #1369e9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        border-radius: 8px;
        color: #ffffff;
      }

      .timer-section {
        display: flex;
        align-items: center;

        .time-left {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #5f6989;
          margin-right: 16px;
          display: flex;
          align-items: center;

          svg {
            margin-right: 4px;
          }
        }

        .timer {
          margin-right: 23px;
          display: flex;
          align-items: center;

          span {
            margin-left: 10px;
            margin-right: 10px;
            color: #8d98b1;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
          }

          .time-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 12px;
            width: 48px;
            height: 40px;
            background: rgba(225, 225, 238, 0.8);
            border-radius: 8px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #252e4b;
          }
        }

        .end-button {
          gap: 8px;
          width: 112px;
          height: 40px;
          right: 10px;
          background: #ffffff;
          border: 1px solid #dadae4;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 8px;
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #252e4b;

          &:hover {
            background: #252e4b;
            color: #dadae4;
          }
        }

        .stopSharing-button {
          padding-left: 8px;
          gap: 8px;
          display: flex;
          align-items: center;
          margin-right: 12px;
          height: 40px;
          width: 166px;
          right: 10px;
          background: #ffffff;
          border: 1px solid #dadae4;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 8px;
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ff475d;
        }
      }
    }

    .button-section {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      // position: absolute;
      // bottom: 20px;

      .right-button-sec {
        display: flex;
      }

      button {
        height: 40px;
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        border: 1px solid #dadae4;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;

        &:hover {
          background: #252e4b;
          color: #dadae4;
        }
      }

      .previous {
        padding: 8px 12px;
        gap: 6px;
        width: 85px;
        height: 40px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        background: #ffffff;
        color: #252e4b;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .skip {
        padding: 8px 16px;
        gap: 8px;
        width: 83px;
        background: #ffffff;
        border: 1px solid #dadae4;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        color: #252e4b;
        margin-right: 12px;
      }

      .next {
        padding: 8px 12px 8px 16px;
        gap: 8px;
        width: 85px;
        height: 40px;
        background: #1369e9;
        border: 1px solid #1369e9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 10px;
        }
      }
    }

    .video-section {
      height: calc(100vh - 160px);
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .modal-backdrop, .backdrop {
    opacity: 0.98;
    background-color: #ecf3ff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
  }

  .modal.fade.in {
    display: block;
  }

  // Zoom SDK
  #ZoomMeet.zoomMeet {
    right: 0px !important;
    bottom: 225px !important;
    width: 215px;

    position: absolute;
    // min-height: 500px;
    // top: 200px;

    .zmwebsdk-makeStyles-video-266,
    .zmwebsdk-makeStyles-video-51 {
      top: 0px;
      // left: 50%;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      border-radius: 6px;
    }

    .zmwebsdk-makeStyles-root-398,
    .zmwebsdk-makeStyles-root-183 {
      // max-width: 48%;
      // max-height: 50%;
      display: none;
      // top: 10px;
      // left: 50%;
    }
  }

  #ZoomMeet.interview {
    right: 0px !important;
    bottom: 225px !important;
    width: 233px;
    position: absolute;
    // height: calc(100vh - 114px - 60px);
    top: 85px;

    // .zmwebsdk-makeStyles-videoCustomize-223,
    // .zmwebsdk-makeStyles-singleView-222,
    // .zmwebsdk-makeStyles-singleView-7 {
    //   height: 100%;
    // }

    // .zmwebsdk-makeStyles-root-245,
    // .zmwebsdk-makeStyles-videoCustomize-8 {
    //   height: 100%;
    // }

    // .zmwebsdk-makeStyles-video-266,
    // .zmwebsdk-makeStyles-video-51 {
    //   width: 100%;
    //   height: 40%;
    //   padding: 8px;
    //   display: block;
    //   position: absolute;
    //   border-radius: 6px;
    // }

    // .zmwebsdk-makeStyles-root-30 {
    //   height: 92%;
    // }

    // .zmwebsdk-makeStyles-root-398,
    // .zmwebsdk-makeStyles-root-183 {
    //   max-width: 100%;
    //   max-height: 100%;
    //   top: 10px;
    //   left: 50%;
    // }
  }

  @import 'assessments/coding_section';
  @import 'assessments/landing_page';
}

#mcqSection {

  // left and right section height = screen_height (100vh) - (screen_v_padding (24px) + heading_all_height (90px)) = 100vh - 114px
  .right-section {
    position: absolute;
    right: 0px;
    width: 260px;
    height: calc(100vh - 114px);
    overflow: hidden;

    .question-index-section {
      height: calc(100vh - 176px - 173px);
      padding: 18px 20px;
      background: #fdfeff;
      border-radius: 12px;
      box-shadow: 0px 2px 4px rgba(62, 72, 84, 0.1);

      .heading {
        padding-bottom: 18px;
        border-bottom: 1px solid#EFF0F2;

        span {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #252e4b;
        }
      }

      .question-numbers {
        display: flex;
        flex-wrap: wrap;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 22px;
      }

      .question-number {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .visited {
        background: #ffffff;
        border: 1px solid #e1e1ee;

        span {
          color: #5f6989;
        }
      }

      .skipped {
        background: #eff0f2;

        span {
          color: #5f6989;
        }
      }

      .answered {
        background: #f3f9e6;
        border: 1px solid #80bb01;

        span {
          color: #80bb01;
        }
      }

      .active {
        background: #252e4b;

        span {
          color: #ffffff;
        }
      }

      .timer {
        margin-top: 10px;
        border-bottom: 1px solid #dce8ff;

        .heading {
          display: flex;
          align-items: center;
          justify-content: center;
          color: red;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
        }

        .time-text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          padding-bottom: 20px;
        }
      }

      .indecator-section {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 16px;
        background: #f9f9f9;
        right: 0px;
        border-radius: 0px 0px 12px 12px;

        .text {
          margin-top: 10px;

          // font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #252e4b;
          display: flex;
          align-items: center;

          svg {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .left-section {
    height: calc(100vh - 114px);
    width: calc(100% - 260px - 16px);

    .question-numbers-bottom {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -56px;
      right: 0;

      button {
        z-index: 99;
        margin-right: 5px;
      }
    }

    .question-numbers {
      display: flex;
      flex-wrap: wrap;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      justify-content: center;
      margin-top: 22px;
    }

    .question-number {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .visited {
      background: #ffffff;
      border: 1px solid #e1e1ee;

      span {
        color: #5f6989;
      }
    }

    .skipped {
      background: #eff0f2;

      span {
        color: #5f6989;
      }
    }

    .answered {
      background: #f3f9e6;
      border: 1px solid #80bb01;

      span {
        color: #80bb01;
      }
    }

    .active {
      background: #252e4b;

      span {
        color: #ffffff;
      }
    }

    .question-section {
      background: #fdfeff;
      box-shadow: 0px 2px 6px rgba(0, 27, 75, 0.02);
      border-radius: 12px;
      height: calc(100vh - 114px - 60px);
      padding: 24px 28px;
      box-shadow: 0px 2px 4px rgba(62, 72, 84, 0.1);

      .question-sub-section {
        height: 100%;
        overflow: scroll;
      }

      .questionNumber {
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 122.2%;
        letter-spacing: 0.02em;
        color: #5f6989;
      }

      .question-text {
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -0.01em;
        color: #252e4b;
        margin-top: 14px;
        // margin-bottom: 36px;
      }

      .under-text {
        font-style: italic;
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 122.2%;
        letter-spacing: 0.01em;
        color: rgba(141, 152, 177, 0.8);
      }


      .audio-gif {
        margin-top: 32px;
        img {
          width: 137px;
          height: 36px;
        }
      }

      .interview-instruction-section {

        background: #F9F9F9;
        border: 1px solid #E1E1EE;
        border-radius: 8px;
        padding: 14px 17px 14px 17px;
        margin-top: 40px;


        span {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #252E4B;
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
          }
        }

        li {
          font-style: normal;
          font-weight: 400;
          margin-top: 7px;
          font-size: 13px;
          line-height: 24px;
          color: rgba(37, 46, 75, 0.9);
          list-style-type: disc;
          padding-left: 5px;
        }
      }

      .question-text:last-of-type {
        margin-bottom: 36px;
      }

      .option {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        input {
          margin: 0 10px 0 0;
          width: 22px;
          height: 22px;
        }

        label {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #252e4b;
          margin: 0;
        }
      }
    }
  }
}

#Int-start-modal {
  .header-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -46px;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    span {
      // font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      align-items: center;
      color: #252e4b;
    }
  }

  .instruction {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;

    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #5f6989;
    }
  }

  .int-info {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }

  .int-info-subsection {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    border: 1px solid #e1e1ee;
    border-radius: 12px;
    height: 77px;

    .video-logo {
      align-items: flex-start;
      padding: 10px;
      gap: 10px;
      width: 44px;
      margin-right: 16px;
      height: 44px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(62, 72, 84, 0.1);
      border-radius: 12px;
    }

    .info-text {
      display: flex;
      flex-direction: column;

      .position-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #252e4b;
      }

      .sproutsai-text {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #8d98b1;
      }
    }
  }

  .button-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 42px;

    .start-button {
      height: 48px;
      background: #1369e9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;

      svg {
        margin-left: 11px;
      }
    }

    .cancel-button {
      margin-top: 22px;
      border: none;
      background: none;
      // font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #252e4b;
    }
  }
}

#Code-editor {
  .header-section {
    position: absolute;
    top: -68px;
    // background: #c5d0f3;
    display: flex;
    justify-content: space-between;
    padding: 26px 0 20px 0;

    .jobPosition-section {
      display: flex;
      align-items: center;

      button {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #e1e1ee;
        border-radius: 10px;
      }

      .position-text {
        display: flex;
        flex-direction: column;
        margin-left: 14px;

        .text {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
          color: #252e4b;
        }

        a {
          // font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.01em;
          color: #5f6989;

          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .timer-section {
      display: flex;
      align-items: center;

      .time-left {
        // font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #5f6989;
        margin-right: 16px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }

      .timer {
        margin-right: 23px;
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .time-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 12px;
        width: 48px;
        height: 40px;
        background: rgba(225, 225, 238, 0.8);
        border-radius: 8px;
      }

      button {
        gap: 8px;
        width: 112px;
        height: 40px;
        right: 10px;
        background: #ffffff;
        border: 1px solid #dadae4;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;

        // font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #252e4b;
      }
    }
  }

  .button-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .right-button-sec {
      display: flex;
    }

    button {
      height: 40px;
      border-radius: 8px;
      // font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      border: none;
      background: none;
    }

    .previous {
      padding: 8px 18px 8px 12px;
      gap: 6px;
      width: 191px;
      background: rgba(51, 52, 142, 0.05);
      color: #252e4b;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 6px;
      }
    }

    .next {
      padding: 8px 12px 8px 16px;
      gap: 8px;
      width: 162px;
      height: 40px;
      background: #1369e9;
      border: 1px solid #1369e9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      color: #ffffff;
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }
}