#custom-ant-table{
    .ant-table{
        background: transparent;
        border: none;
    }
    .ant-table-thead{
        background: transparent;
        .ant-table-cell{
            background: transparent;
            border: none;
        }
    }
    .ant-table-tbody{
        background-color: white;
        border-radius: 16px;
    }
}