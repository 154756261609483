@tailwind base;
@tailwind utilities;
@import "_style_guide.css";

html {
  font-size: 16px;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

.job-description ul {
  list-style: disc;
  margin: auto !important;
  padding-left: 40px;
}

.job-description li {
  margin: 10px 0;
}

.scrollbar-hide {
  /* Hide the scrollbar track and thumb */
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  /* background-color: #f2f2f2; */

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0;
  /* Set the width to 0 to hide the scrollbar */
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  display: none;
  width: 0px;
  height: 0px;
  /* Hide the scrollbar thumb */
}

.scrollbar-candidate::-webkit-scrollbar {
  width: 0.5em;
  height: 10px;

  /* Set the width to 0 to hide the scrollbar */
}

.scrollbar-candidate::-webkit-scrollbar-thumb {
  /* display: none; Hide the scrollbar thumb */
  height: 0.5em;
  border-radius: 30px;
  background: rgba(51, 52, 142, 0.15);
}

.bar-left {
  box-shadow: none !important;
}

.bar-right {
  box-shadow: none !important;
}

.bar-inner {
  box-shadow: none !important;
  border: none !important;
}

.thumb {
  box-shadow: none !important;
  border: none !important;
}

:root {
  --gray-gray-4: #e1e1ee;
  --gray-gray-1: #fff;
  --shadow-color: rgba(0, 0, 0, 0.04);
}

.no-list-style li {
  list-style-type: none;
}

button {
  transition: all 0.3s ease;
}

.border-button:hover {
  border: 1px solid var(--bright-greek-blue-9, #145ecc);
  background: var(--gray-gray-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--bright-greek-blue-9, #145ecc);
}

.border-button:focus {
  border: 1px solid var(--bright-greek-blue-7, #4287ed);
  background: var(--gray-gray-1, #fff);
  box-shadow: 0px 0px 0px 3px rgba(49, 70, 245, 0.2);
  color: var(--bright-greek-blue-9, #145ecc);
}

.solid-button-blue:hover {
  border-radius: 8px;
  border: 1px solid var(--bright-greek-blue-9, #145ecc);
  background: var(--bright-greek-blue-9, #145ecc);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.solid-button-blue:focus {
  border: 1px solid var(--bright-greek-blue-7, #4287ed);
  background: var(--bright-greek-blue-7, #4287ed);
  box-shadow: 0px 0px 0px 3px rgba(49, 70, 245, 0.2);
}

.sc-eqUAAy {
  .sc-gEvEer:hover {
    background-color: #fff;
  }
}

.sc-tagGq {
  video {
    object-fit: contain !important;
  }
}

.ch-video {
  object-fit: contain;
}

h4::first-letter {
  text-transform: capitalize;
}

h6::first-letter {
  text-transform: capitalize;
}

span::first-letter {
  /* text-transform: capitalize; */
}

.no-scrollbar2 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.multi-range-slider .thumb::before {
  /* border: rgba(51, 52, 142, 0.04); */
  border: none !important;
  box-shadow: none !important;
  background-color: #e2e7f0 !important;
  /* box-shadow: rgba(62, 72, 84, 0.2) 0px; */
}

.wrapper-class {
  border: 1px solid #e1e1ee;
  border-radius: 8px;
  width: 100%;

  .rdw-editor-main {
    border: 1px #4287ed;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    /* background-color: #f2f2f2; */
  }

  .editor-class {
    padding-left: 20px;
    min-height: 200px;
  }

  .toolbar-class {
    background: rgba(51, 52, 142, 0.04);
    border: 1.6px solid #f4f8ff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .rdw-block-wrapper {
    border-right: 1px solid black;
    padding-left: 3px;
    padding-right: 3px;
  }

  .rdw-list-wrapper {
    border-right: 1px solid black;
    padding-left: 3px;
    padding-right: 3px;
  }

  .rdw-text-align-wrapper {
    border-right: 1px solid black;
    padding-left: 3px;
    padding-right: 3px;
  }

  .rdw-option-wrapper {
    background: none;
    height: 30px;
    width: 30px;
    box-shadow: none;
    border: none;
  }

  .rdw-option-wrapper img {
    width: 12px;
  }

  .rdw-option-wrapper:hover {
    background: #fff;
  }

  .rdw-option-wrapper {
    background: none;
    border: none;
  }

  .rdw-remove-wrapper {
    display: none;
  }

  .rdw-embedded-wrapper {
    display: none;
  }

  .show-image-editor .rdw-image-wrapper {
    display: flex !important;
  }

  .rdw-image-wrapper {
    display: none;
  }

  .rdw-emoji-wrapper {
    display: none;
  }

  .rdw-colorpicker-wrapper {
    display: none;
  }

  /* 
  .rdw-dropdown-wrapper {
    display: none;
  } */
}

.pagination .next a {
  border: none;
  background: none;
}

.pagination li a {
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  border-radius: 50%;
  margin-right: 5px;
  color: #5f6989;
  font-size: 14px;
}

.pagination .previous a {
  position: absolute;
  left: 0px;
  width: 100px;
  border-radius: 4px;
  height: 40px;
}

.pagination .next a {
  position: absolute;
  right: 0px;
  width: 100px;
  border-radius: 4px;
  height: 40px;
  border: none;
}

.pagination li.active a {
  background-color: #1369e9;
  color: white;
}

.pagination li a:active {
  background-color: #1369e9;
  color: white;
}

.google-oauth-signin {
  height: 48px !important;
}

.google-oauth-signin iframe {
  height: 48px !important;
}

.ant-btn-primary {
  background-color: #145ecc;
}

.rce-mbox-text::after {
  display: none !important;
}
