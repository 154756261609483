#evaluation {
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-top: 10px;
    }
  }

  .heading-row {
    padding-left: 18px;
    padding-right: 18px;
    h5:nth-of-type(3) {
      display: flex;
      justify-content: flex-end;
      color: #1369e9;
      gap: 6px;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        svg path {
          stroke-width: 2px;
        }
      }
    }
  }

  .heading-row h5,
  .data-row .data-item {
    &:nth-of-type(1) {
      width: 40%;
    }

    &:nth-of-type(2) {
      width: 30%;
    }

    &:nth-of-type(3) {
      width: 30%;
    }
  }
  .data-row {
    padding: 20px 8px 20px 18px;
    &:nth-of-type(1) h4,
    &:nth-of-type(1) h6,
    &:nth-of-type(2) {
      text-transform: capitalize;
    }
    h4 {
      margin: 0;
      margin-bottom: 4px;
      color: #252e4b;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }
    .social-logo {
      display: flex;
      align-items: center;
      a {
        display: flex;
        width: 24px;
        height: 24px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e1e1ee;
        background: #fff;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.04);
      }
    }

    .match {
      .chart-section {
        display: flex;
        align-items: center;

        span {
          margin-left: 6px;
          color: #252e4b;
          font-size: 13px;

          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .button-section {
      display: flex;
      justify-content: space-around;
      button {
        border-radius: 8px;
        border: 1px solid #dadae4;
        background: #fff;
        display: flex;
        height: 32px;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        &.toggle:hover svg path {
          stroke: #1369e9;
          stroke-width: 2px;
        }
      }
      a:hover {
        text-decoration: none;
        button {
          box-shadow: 0 0 4px #dadae4;
          background: #f5f5f5;
        }
      }
    }
  }
  .accordion {
    position: "relative";
    height: "auto";
    h4 {
      padding: 4px 16px;
      display: inline-block;
    }
  }
  .interview {
    .header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      &:first-of-type:hover svg path {
        stroke: teal;
        stroke-width: 3px;
      }
    }
    h2 {
      font-size: 20px;
      font-weight: 500;
      line-height: normal;
      margin-top: 0;
    }
    .overall-section {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: space-between;
      .interviewer span {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        &.interviewer-name {
          color: #252e4b;
        }
        &.interviewer-text {
          color: #5f6989;
          margin-left: 12px;
        }
      }
      .date {
        display: inline-block;
        margin-top: 32px;
        .label-text {
          color: #5f6989;
          font-size: 14px;
          padding: 0 0 6px;
        }
        .value {
          font-size: 16px;
          font-weight: 500;
          color: #252e4b;
        }
      }
      .overall-score {
        margin-right: 20px;
        border-radius: 16px;
        background: #fff;
        padding: 10px 16px;
        width: 150px;
        align-self: flex-end;
        span {
          line-height: normal;
          margin: 0;
        }
        .score {
          color: #16182f;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: -0.96px;
          display: block;
        }
        .label-txt {
          color: #252e4b;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .criteria-score {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid #dadae4;
      .criteria-row {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 24px;
        margin-bottom: 8px;
        .score {
          width: 50px;
          margin-right: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
          }
        }
        .criteria {
          width: calc(100% - 120px);
        }
      }
    }
  }
}
#insights{
  // display: flex;
  h5{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #363636;
  }
  .sub-heading{
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    line-height: 24px;
    color: #363636;
  }

  .imgae-section{
    .img-first{
      height: 300px;
      width: 90%;
      padding-left: 15px;
      object-fit: contain;
    }
    .img-second{
      height: 200px;
      width: 80%;
      margin-top: 20px;
      object-fit: contain;
    }

    width: 100%;

  }
  
}