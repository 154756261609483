// Left narrow list and right wider details
.list-detail-view {
  & > .left-section,
  & > .right-section {
    border: solid white;
    border-width: 10px 0;
    height: calc(100vh - $navbar-height - 74px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  & > .left-section {
    .list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 4px;
      padding: 16px;
      &:hover {
        background-color: #f4f8ff;
      }
      &.active {
        background: #e0ebff;
      }
    }
  }

  & > .right-section {
    padding: 0px 16px;
  }
  .top-section {
    // position: sticky;
    // top: 0;
    // background: white;
    // z-index: 9;
    .button-section {
      margin: 16px 0;
      @include text16;
      border-bottom: 2px solid #d7e5fd;
      font-weight: 500;
      button {
        // padding: 0 12px 2px 0;
        // background: none;
        // border: 0;
        // margin-bottom: -2px;
        // border-bottom: 2px solid transparent;

        // &:not(:last-of-type) {
        //   margin-right: 10px;
        // }

        // &:hover {
        //   border-bottom-color: #a7c6ff;
        //   text-decoration: none;
        // }

        // &.active {
        //   border-bottom-color: $color3;
        // }
      }
    }
  }

  .bottom-section {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
    &::-webkit-scrollbar {
      width: 0;
    }

    .tab-section {
      &:last-of-type {
        min-height: 75vh;
      }

      &:not(:first-of-type) section {
        padding-top: 50px;
      }
    }
  }
}
