#createCompany {
  .main-content {

    display: flex;
    height: calc(100vh - 140px);
    align-items: center;
    align-content: center;
    justify-content: center;


    .sub-container {
      width: 872px;
      box-shadow: 0px 4px 24px rgb(0 27 75 / 6%);
      border-radius: 24px;
      padding: 50px 5% 50px;
    }

    form {
      flex-direction: row;
      justify-content: space-between;

      .left-section {
        img {
          width: 156px;
          height: 156px;
          border-radius: 50%;
          object-fit: contain;
        }

        label {
          width: 156px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $color3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .right-section {
        float: right;
      }
    }

    .headingText {
      text-align: left;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;
    }

    input[type="file"]::-webkit-file-upload-button {
      visibility: hidden;
    }

    input[type="text"],
    textarea {
      width: 100%;
      border: 1px solid $color3;
      border-radius: 8px;
   
    }

    label,
    textarea {
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #767676;
      margin-top: 20px;

      &[required]::after {
        content: " *";
        color: red;
      }
    }

    input[type="text"] {
      height: 44px;
    }

    textarea {
      height: 108px;
      margin-top: 0;
      padding: 10px 20px;
    }

    .carousel-indicators {
      top: 50px;
      bottom: inherit;
      justify-content: space-between;
      width: 758px;
      height: 12px;
      margin: 0 57px;
      align-self: center;
      left: inherit;

      li {
        background-color: $color2;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0;
        border: 6px solid white;

        &.active {
          outline: 2px solid $color3;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        top: 11px;
        z-index: -1;
        height: 2px;
        background-color: #85adf9;
      }
    }

    .controller {
      float: right;
      text-align: right;
      margin-top: 60px;

      button {
        @include button(auto, 36px);
        margin-top: 0;
        display: inline-flex;
        border-radius: 4px;
        padding: 0 20px;

        .label {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.01em;
          font-weight: 500;
        }
      }

      a {
        cursor: pointer;
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.01em;
        color: #001b4b;
      }

      .right {
        margin-left: 20px;
      }
    }

  }
}