svg {
  .graph rect:hover {
    z-index: 99;
    outline: 2px groove #00000062;
    border-radius: 4px;
  }

  &:hover .graph path:not([fill="transparent"]) {
    stroke: #252523;
    stroke-width: 1px;
    z-index: 99;
  }
  &.semiDonutChart:hover .arc path {
    stroke: none !important;
    cursor: pointer;
  }

  text {
    font-weight: 600;
  }

  rect.progress-bar:hover {
    stroke: #252523 !important;
  }

  .xAxis,
  .yAxis {
    text,
    text tspan {
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.legend {
 
  .legend_text {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .color {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
    }
  }
}

