.candidate-pages#create-profile {
  display: flex;
  align-items: center;

  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .sub-container {
    width: 872px;
    box-shadow: 0px 4px 24px rgba(0, 27, 75, 0.06);
    border-radius: 24px;
    padding: 100px 5% 50px;


    label {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #767676;
      margin-top: 20px;
    }

    .checkbox-input {
      margin-top: 10px;

      label {
        margin-top: 0px;
        margin-left: 20px;
      }

      input {
        width: 12px;
        height: 12px;
      }
    }

    select,
    input[type="text"],
    input[type="date"],
    textarea {
      width: 100%;
      border: 1px solid #CBD5E0;
      padding: 8px;
      border-radius: 8px;
      font-size: 16px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);

      /* Hover state */
      select,
      input[type="text"],
      input[type="date"],
      textarea:hover {
        border-color: #1369E9;
      }

      /* Focus state */
      select,
      input[type="text"],
      input[type="date"],
      textarea:focus {
        border-color: #1369E9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
      }

      /* Placeholder styles */
      select,
      input[type="text"],
      input[type="date"],
      textarea::placeholder {
        font-size: 16px;
        font-weight: normal;
        color: #5F6989;
      }
    }


    input:disabled {
      border: 1px solid #899dc3;
      background-color: #e5e0e0;
    }

    h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 30px;
      font-family: "Montserrat", sans-serif;
      letter-spacing: 0.02em;
      color: #363636;
    }

    textarea {
      width: 100%;
      resize: none;
      border-radius: 8px;
      min-height: 200px
    }

    .start {
      padding-left: 0px
    }

    .end {
      padding-right: 0px
    }

    button {
      @include button(356px, 56px);
      margin: 64px 0 0;
    }

    .remove {
      float: right;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }

    .carousel-indicators {
      top: 50px;
      bottom: inherit;
      justify-content: space-between;
      width: 758px;
      height: 12px;
      margin: 0;
      align-self: center;
      left: inherit;
      display: flex;

      //   .after_indicator {
      //     left: 6px;
      //     top: 1px;
      //     width: 7px;
      //     height: 13px;
      //     // border: solid white;
      //     border-width: 0 3px 3px 0;
      //     // -webkit-transform: rotate(45deg);
      //     // -ms-transform: rotate(45deg);
      //     transform: rotate(45deg);
      // }



      li {

        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0;

        span {
          display: flex;
          align-items: center;
          background-color: $color2;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          border: 6px solid white;
        }

        &.active {
          outline: 2px solid $color3;

          svg {
            display: none;
          }
        }



        svg {
          position: absolute;
        }
      }



      &::after {
        content: "";
        position: absolute;
        width: 100%;
        top: 11px;
        left: 0;
        z-index: -1;
        height: 2px;
        background-color: #85adf9;
      }
    }

    .carousel-indicators-label {
      display: flex;
      justify-content: space-between;
      margin-top: -90px;

      span {
        width: 24px;
        font-weight: 600;
        font-size: 14px;
        padding-left: 10px;
      }

      span:first-of-type {
        padding-left: 0px;
      }
    }

    .controller {
      float: right;
      text-align: right;
      margin-top: 60px;

      button {
        margin-top: 0;
        display: inline-flex;
        width: 128px;
        height: 40px;
        border-radius: 4px;
        background: #1369E9;


        .label {
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.01em;
          font-weight: 500;
          color: #ffffff;
          padding: 0;
        }
      }

      a.right {
        cursor: pointer;
        font-family: $sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.01em;
        color: #001b4b;
      }

      button:hover {
        background-color: #85adf9
      }

      button.left {
        width: auto;
        padding: 0 16px;
        margin-left: 20px;
        background: #FFFFFF;
        border: 1px solid #DADAE4;




        .label {
          color: var(--Gray-gray---8, #252E4B);
          text-align: center;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

      .right {
        margin-left: 20px;
      }
    }

    .headingText {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      font-family: $sans-serif;
      letter-spacing: 0.01em;
      color: $color5;
    }

    .exp-data,
    .edu-data {
      .left-section {
        h4 {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #373737;
        }

        h5 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #373737;
        }
      }

      .right-section {
        text-align: right;

        h5 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #3E3E3E;
        }
      }

      p {
        font-weight: 500;
        font-size: 16px;
        color: #636363;
      }
    }
  }
}

#ConfirmationPage-slide {
  .resume-modal-button {
    display: none;
  }

  .edit {
    display: none;
  }

  .add-button {
    display: none;
  }
}