#setting {
    background: #F4F8FF;
    margin-top: 20px;

    .main-content {
        font-style: normal;

        input {
            padding-left: 20px;
          
        }

        select {
            padding-left: 20px;
        }

        .react-tel-input .flag-dropdown {
            height: 40px;
            display: flex;
            top: auto;
            bottom: -4px;
            width: 50px;
            background: #F4F8FF;
            border-radius: 3px 0px 0px 3px;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid var(--gray-gray-4, #E1E1EE);
            // background: var(--gray-gray-1, #FFF);
            border-right: none;
        }

        .react-tel-input .selected-flag:hover,
        .react-tel-input .selected-flag:focus {
            background-color: #F4F8FF;

        }

        .react-tel-input .country-list {
            top: 35px;
            max-height: 400px;

        }

        .react-tel-input .country-list::-webkit-scrollbar {
            width: 10px;

        }

        .react-tel-input .country-list::-webkit-scrollbar-thumb {
            background: #c5c5c5;
            border-radius: 10px;
            height: 40px;
        }

        .react-tel-input {
            input {
                height: 40px;
                padding-left: 65px;
                top: 4px;
                width: 100%;
            }
        }

        .label-input {
            color: var(--gray-gray-8, #252E4B);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 24px;
        }

        .left-sec {
            background: #ffffff;
            height: calc(100vh - 88px);
            width: 264px;
            position: fixed;
            display: flex;
            flex-direction: column;

            button {
                display: flex;
                align-items: center;
                height: 36px;
                margin-top: 10px;
                border: none;

                svg {
                    margin-right: 20px;
                }

            }

            button:hover {
                background: #F4F8FF;
            }
        }

        .heading-setting {
            display: flex;
            align-items: center;
            border-bottom: 1.4px solid #E1EDFF;
            padding-top: 22px;
            padding-bottom: 22px;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 16px;
                object-fit: cover;
            }

            span {

                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #363636;
            }
        }

        .right-sec {
            position: fixed;
            left: calc(264px + 24px);
            width: calc(100vw - 264px - 34px);
        }

        .sub-sections {
            background: #ffffff;
            padding-bottom: 24px;

            .warning {
                color: red;
            }

            .code-button {
                margin-top: 20px;

                p {
                    font-size: 1.724vh;
                    line-height: 1.5;
                    letter-spacing: 0.02em;
                    color: #606060;
                    font-family: "Montserrat", sans-serif;
                    font-style: normal;
                    display: flex;
                }

                a {

                    display: flex;
                    align-items: center;
                    margin-left: 3px;

                    span {
                        color: #0b53dd;
                        margin-left: 2px;
                    }

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }


        #general-setting {
            .heading-section {
                display: flex;
                padding-top: 22px;
                padding-bottom: 22px;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1.4px solid #E1EDFF;

                span {
                    font-weight: 500;
                    font-size: 18px;
                    color: #363636;
                }

                button {
                    width: 90px;
                    height: 32px;
                    background: #FFFFFF;
                    border: 1.4px solid #0B53DD;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #0B53DD;
                }
            }

            .heading-section-delete {
                display: flex;
                padding-top: 22px;
                padding-bottom: 22px;
                align-items: center;
                border-bottom: 1.4px solid #E1EDFF;

                span {
                    font-weight: 500;
                    font-size: 18px;
                    color: #363636;
                    margin-left: 12px;
                }


                button {
                    border: none;
                    background: none;
                }
            }

            .input-left-sec {
                padding-right: 18px;
            }

            .input-right-sec {
                padding-left: 18px;
            }

         

            .account-setting {
                margin-top: 24px;

                .delete-account {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 24px;

                    button {
                        background: none;
                        border: none;
                        padding: 0;
                    }

                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: #363636;

                    }

                }

            }

            .top-heading {
                margin-top: 16px;
            }

            .save-password {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 146px;
                height: 36px;
                background: #FFFFFF;
                border: 1.4px solid #0B53DD;
                border-radius: 4px;
                color: #0B53DD;
                margin-top: 36px;
            }

            .forget-password {
                background: none;
                margin-top: 16px;
                padding: 0;
                border: none;
                font-weight: 500;
                font-size: 14px;
                color: #363636;
            }

            .servey-input {
                margin-top: 24px;
            }

            .back-button {
                border: none;
                background: #1C5FE1;
            }

            .feedback-textarea {
                border-radius: 8px;
                border: 1px solid var(--gray-gray-4, #E1E1EE);
                background: var(--gray-gray-1, #FFF);
                width: 100%;
                resize: none;
                margin-top: 16px;
            }

            .delete-button {
                justify-content: flex-end;
            }

            .checkbox-sec {
                display: flex;
                align-items: center;
                margin-top: 12px;

                input {
                    margin-top: -8px;
                    background: none;
                    box-shadow: none;
                }

                label {
                    margin-top: 0px;
                    margin-left: 24px;
                }

          
            }

            .notification-setting {

                h4 {
                    font-weight: 500;
                    font-size: 16px;
                    color: #2264E3;
                }

                .sub-heading-sec {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        font-weight: 500;
                        font-size: 14px;
                        color: #363636;

                    }
                }

                .info {
                    font-weight: 500;
                    font-size: 12px;
                    color: #767676;
                    margin-top: 4px;
                }

                .job-recomand {
                    margin-top: 24px;
                }

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 30px;
                    height: 15px;
                }

                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: .4s;
                    transition: .4s;
                }

                .slider:before {
                    position: absolute;
                    content: "";
                    height: 11px;
                    width: 11px;
                    left: 4px;
                    bottom: 2px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                }

                input:checked+.slider {
                    background-color: #1C5FE1;
                }

                input:focus+.slider {
                    box-shadow: 0 0 1px #1C5FE1;
                }

                input:checked+.slider:before {
                    -webkit-transform: translateX(12px);
                    -ms-transform: translateX(12px);
                    transform: translateX(12px);
                }

                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
                }

                .slider.round:before {
                    border-radius: 50%;
                }
            }

        }





    }
}


#chats {
    .main-content {
        .left {
            width: calc(30% - 100px);
            box-shadow: 0px 4px 12px rgba(0, 27, 75, 0.06);
            position: fixed;
            height: calc(100vh - 88px);
            left: 100px;

            .list {
                display: flex;
                background: #FFFFFF;
                box-shadow: 0px 0px 8px rgba(0, 27, 75, 0.04);
                padding: 21px;
                border-radius: 4px;

                img {
                    width: 72px;
                    height: 72px;
                    border-radius: 50%;
                    background: #363636;
                    margin-right: 20px;
                }

                .notification {
                    display: flex;
                    flex-direction: column;
                    height: 72px;
                    justify-content: center;

                    .name {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        color: #363636;
                    }

                    .message {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        color: #767676;
                        margin-top: 10px;

                    }
                }
            }
        }

        .right {
            // background: #ccc;
            height: calc(100vh - 88px);
            position: fixed;
            width: calc(70% - 40px);
            right: 40px;

            .input-section {
                background: #F4F8FF;
                min-height: 84px;
                display: flex;
                align-items: center;

                label {
                    margin-bottom: 0;
                    font-size: 0;
                }


                textarea {
                    width: 100%;
                    min-height: 44px;
                    resize: none;
                    margin-left: 20px;
                    background: #FFFFFF;
                    border: 1px solid rgba(0, 87, 255, 0.45);
                    border-radius: 8px;
                }

                button {
                    position: fixed;
                    right: 85px;
                    margin-top: 10px;
                    background: none;
                    padding: 0;
                    border: none;
                }

            }

            .chat-section {
                // background: #ffffff;
                // background: #363636;
                box-shadow: 0px 4px 12px rgba(0, 27, 75, 0.06);
                min-height: calc(100% - 84px);

                .candidate-info {
                    height: 88px;
                    background: #F4F8FF;
                    display: flex;
                    align-items: center;
                    padding-left: 15px;

                    img {
                        width: 56px;
                        height: 56px;
                        border-radius: 50%;
                        background: #363636;
                        margin-right: 20px;
                    }

                    span {
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        color: #363636;
                    }


                }

                .sender {
                    background: #F4F8FF;
                    display: flex;
                    flex-direction: row-reverse;
                    height: 30px;
                    align-items: center;
                }

                .reciver {
                    // background: #F4F8FF;
                    display: flex;
                    // flex-direction: row-reverse;
                    height: 30px;
                    align-items: center;
                }

            }
        }
    }
}
.custom-pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .custom-pagination li {
    margin: 0 0.5rem;
  }
  
  .custom-pagination li a {
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .custom-pagination li.active a {
    background-color: #007bff;
    color: #fff;
  }
  
  .custom-pagination li a:hover {
    background-color: #f0f0f0;
  }