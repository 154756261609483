.breadcrumbs {
  .breadcrumb-links {
    button {
      background-color: transparent;
      border: 0;
      padding: 0;
    }
    button:hover,
    a:hover {
      color: #0b53dd;
      text-decoration: underline;
      text-decoration-color: #0b53dd;
    }
  }

  .breadcrumb-list {
    position: absolute;
    display: block;
    width: 240px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 99;
    padding: 4px 8px;
    top: 36px;
    border: 2px solid #f4f8ff;
    &#tabs-list {
      left: 264px;
      width: 160px;
    }
    &#job-list {
      left: 125px;
    }
    a {
      padding: 8px 16px;
      &:hover {
        background: #f4f8ff;
      }
      &#candidates,
      &.active {
        background: #e0ebff;
      }
    }
  }
}
