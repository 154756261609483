/*=*=*=*=*=*=*=*=*=*=*=*=* SIDEBAR *=*=*=*=*=*=*=*=*=*=*=*/
#sidebar {
  .toggle {
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 101;
    cursor: pointer;
    left: 24px;
    top: 16px;
    position: fixed;
  }

  .sidebar-menu {
    width: $sidebar-width;
    display: flex;
    padding-left: 0rem;
    position: fixed;
    flex-direction: column;
    top: 68px;
    left: 0;
    height: calc(100vh - $navbar-height);
    border: 0;
    margin: 0;
    border-radius: 0;
    z-index: 93;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 27, 75, 0.04);

    span {
      font-family: $sans-serif;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      color: #363636;
      text-align: center;
    }
    li {
      list-style-type: none;
      padding: 20px 7px 0;
      .menu-toggle {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 5px 0;
        font-weight: 500;
        &.active,
        &:hover {
          text-decoration: none;
          background-color: #f4f8ff;
          border-radius: 2px;
          svg path {
            stroke: url(#gradientShades);
            stroke-width: 2;
          }
          span{
            font-weight: 600;
            color: #23527c;
          }
          &::after {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            right: -8px;
            top: 0;
            background: linear-gradient(
              178.77deg,
              #bed4ff -7.88%,
              #85adf9 -7.87%,
              $color3 98.68%
            );
            border-radius: 8px;
          }
        }
      }
      .submenu {
        position: absolute;
        width: 200px;
        height: auto;
        background: white;
        box-shadow: 0px 2px 8px rgba(0, 27, 75, 0.25);
        border-radius: 4px;
        right: -210px;
        top: 0;
        padding: 5px 0;
        li {
          padding: 0;
        }
        .submenu-toggle {
          padding: 5px 8px;
          font-weight: 500;
          &.active,
          &:hover {
            font-weight: 600;
            text-decoration: none;
            background-color: #f4f8ff;
            border-radius: 2px;
          }
        }
      }

      &#applicants .menu-toggle {
        &:hover,
        &.active {
          svg path {
            stroke-width: 0;
            fill: url(#gradientShades);
          }
        }
      }
    }
  }
}

/*=*=*=*=*=*=*=*=*=*=*=*=* SIDEBAR *=*=*=*=*=*=*=*=*=*=*=*/
