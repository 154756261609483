#eval-report {
  background: #f3f5f9;
  padding: 80px 60px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    top: 0;
    height: 466px;
    background: #16182f;
    left: 0;
  }

  header {
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #16182f;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 94;
    padding: 12px 60px;

    .navbar-section {
      display: flex;
      justify-content: space-between;


      button {
        width: 100px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E1E1EE;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #252E4B;

        svg {
          margin-right: 10px;
        }


      }
    }

    img {
      height: 24px;
    }
  }

  main {
    align-self: center;
    padding: 0 100px;
    width: 1200px;

    .top-section {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }

      h2 {
        font-size: 18px;
        line-height: 122.2%;
        font-weight: 400;
        color: #b6b6b6;
      }

      .user {
        width: auto;
        margin: 30px 0 40px;

        .left {
          max-width: 620px;
          width: auto;

          .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
          }

          .email {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.6);
          }
        }

        .right {
          width: 360px;
          text-align: right;
          margin-left: 50px;

          button {
            padding: 9px 16px 9px 12px;
            gap: 8px;
            width: auto;
            height: 40px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;

            &.resume {
              background-color: transparent;
            }

            &.profile {
              background: rgba(255, 255, 255, 0.12);
              margin-left: 12px;
            }
          }
        }
      }
    }

    .main-card {
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(62, 72, 84, 0.1);
      border-radius: 16px;
      margin-bottom: 32px;
      padding: 12px;

      &.overall {
        padding: 32px;

        h1 {
          font-weight: 600;
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.04em;
          color: #16182f;
          margin-top: 0;
        }

        h2 {
          font-size: 18px;
          line-height: 22px;
          color: #252e4b;
          margin-top: 0;
        }

        .date {
          text-align: right;

          span {
            width: 100%;
            display: block;

            &.date-label {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #5f6989;
              margin-bottom: 6px;
            }

            &.date {
              font-size: 18px;
              line-height: 22px;
              color: #252e4b;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: flex-end;
          justify-content: space-around;

          .graph {
            width: 200px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              position: absolute;

              .arc:first-of-type path {
                fill: #eff0f2;
              }
            }

            span {
              font-weight: 600;
              font-size: 25px;
              line-height: 44px;
              letter-spacing: -0.04em;
              color: #16182f;
            }
          }

          .score-table {
            margin-top: 44px;

            .data-row,
            .heading-row {
              background-color: transparent;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: nowrap;
              color: #252e4b;
            }

            .heading-row {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              height: 20px;
              margin: 14px 0;
            }

            .data-row {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              height: 54px;
              background: #ffffff;

              &:not(:last-of-type) {
                border-bottom: 1px solid#EFF0F2;
              }

              .col-xs-6 {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
              }
            }

            .legend-color {
              display: inline-block;
              width: 14px;
              height: 14px;
              margin-right: 8px;
            }
          }
        }
      }

      .header-banner {
        height: 70px;
        padding: 4px 12px;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        cursor: pointer;

        label,
        p {
          margin: 0;
        }

        h3 {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          margin-top: 0;
          color: #252e4b;
        }

        div>p {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #252e4b;
        }

        .right {
          text-align: right;
        }

        span {
          display: inline-block;
          height: 62px;
          border-radius: 12px;
          padding: 10px 14px;

          &.total-questions {
            padding: 10px 16px;
            gap: 2px;
            width: 132px;
            background: #f9f9f9;
            text-align: center;
          }

          &.correct-questions p {
            color: #00ac85;
          }

          &.incorrect-questions p {
            color: #ff475d;
          }

          &.skip-questions p {
            color: #8d98b1;
          }

          &:not(.total-questions) p {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
          }

          label {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            color: #5f6989;
            width: 100%;
          }
        }
      }

      .question-section {
        background: rgba(239, 240, 242, 0.4);
        border-radius: 16px;
        padding: 8px;
        margin-top: 25px;

        .header-row {
          height: 40px;
          padding: 0 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          flex-direction: row;

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #252e4b;
          }
        }

        .question-row {
          background: #ffffff;
          border-bottom: 1px solid #eff0f2;

          .question {
            cursor: pointer;
            padding: 18px;
          }

          .qu {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #252e4b;
          }

          .qu-ans {
            padding: 8px 32px 20px;

            span.label {
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              text-align: center;
              color: #5f6989;
            }
          }
        }

        &.mcq .qu-ans {
          background-color: white;
          list-style-type: none;
          margin-bottom: 0;

          li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 4px;
            margin-bottom: 4px;
            border-radius: 4px;

            input,
            label {
              margin: 0;
            }

            &.correct {
              background-color: #00ac8456;
            }

            &.wrong {
              background-color: #ff475d56;
            }
          }

          label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #252e4b;
            margin-left: 12px !important;
          }
        }

        &.coding .qu-ans {
          .editor-wrapper {
            padding: 8px 0;
            border-radius: 12px;
            background: #1e1e1e;

            &::before {
              width: 100%;
              height: 100%;
              content: '';
              position: absolute;
              top: 0;
              z-index: 10;
              cursor: initial;
            }
          }

          .output-wrapper {
            margin-top: 20px;

            .output-section {
              height: 38%;
              margin-top: 2%;
              background: #f9f9f9;
              border-radius: 12px;
              overflow: hidden;

              .loader-section {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .right-code-output,
              .left-code-output {
                overflow: auto;
              }

              .right-code-output {
                background: #ffffff;
                height: 100%;

                .data-section {
                  margin-bottom: 20px;

                  .heading-section {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #252e4b;
                  }

                  .response-section {
                    padding: 6px 10px 6px 12px;
                    background: rgba(239, 240, 242, 0.5);
                    border-radius: 8px;
                    min-height: 33px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 21px;
                    color: #252e4b;
                  }
                }
              }

              .left-code-output {
                padding: 8px;

                .list-item {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 0;
                  height: 48px;
                  border-radius: 4px;
                  border: 0;
                  background: transparent;
                  width: 100%;

                  &:first-of-type {
                    background-color: #dadae4;
                  }

                  svg {
                    margin: 13px;
                  }

                  h4 {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #252e4b;
                  }
                }
              }
            }
          }
        }

        &.project .qu-ans p {
          padding: 20px;
          border-radius: 12px;
          background-color: #f9f9f9;
        }
      }
    }

    .heading {
      margin-bottom: 18px;

      h2 {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #252e4b;
        margin: 0;
      }

      div.col-xs-6 {
        text-align: right;
      }

      button {
        padding: 8px 16px 8px 10px;
        gap: 8px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #dadae4;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px;
        margin-left: 12px;
      }
    }
  }
}


#report-invite{
  display: flex;
  align-items: center;
  justify-content: center;

  input{
    width: 50%;
    height: 40px;
    padding-left: 36px;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716%27 height=%2714%27 viewBox=%270 0 16 14%27 fill=%27none%27%3E%3Cg clipPath=%27url%28%23clip0_1333_123326%29%27%3E%3Crect x=%270.727539%27 y=%270.831249%27 width=%2714.5455%27 height=%2712.6%27 rx=%273%27 stroke=%27%235F6989%27/%3E%3Cpath d=%27M0.727539 3.63125L5.55989 7.35216C6.9983 8.45974 9.00223 8.45974 10.4406 7.35216L15.273 3.63125%27 stroke=%27%235F6989%27 strokeLinecap=%27round%27 strokeLinejoin=%27round%27/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=%27clip0_1333_123326%27%3E%3Crect width=%2716%27 height=%2714%27 fill=%27white%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 10px center;

  }
  .send-button{
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    width: 84px;
    background: #1369E9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 8px;
    margin-left: 8px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }
  .cancel-button{
    position: absolute;
    border: none;
    background: none;
    right: -35px;
    top: -65px;
  }
}